import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";

import { AuthProps, DefaultAndAuthProps, DefaultProps } from "model/props";
import { Admin } from "model/admin";
import { Account } from "model/account";
import TopMenu from "components/topmenu/TopMenu";

import css from "./app.module.scss";
import { readToken } from "misc/helpers";
import { Paths } from "appConfig";
import { useLoader } from "misc/loader";
import Loader from "components/loader/Loader";
import { retrieveAccessToken, signInWithAccessToken } from "misc/auth_utils";
import PasswordResetScreen from "screens/login/PasswordResetScreen";
import LoginScreen from "screens/login/LoginScreen";
import AccountScreen from "screens/account/AccountScreen";
import HomesScreen from "screens/homes/HomesScreen";
import { Home } from "model/home";
import UsersScreen from "screens/users/UsersScreen";
import StaffScreen from "screens/staff/StaffScreen";
import { StatsAndMergedSummary } from "model/stats";
import StatsScreen from "screens/stats/StatsScreen";
import { apiGetHomesList } from "net/requests";
import CalendarScreen from "screens/calendar/CalendarScreen";

const start_path = window.location.pathname;
const start_token = readToken();

function App() {
  ///////////////////////////////////////////////////////////////////////////

  const [admin, setAdmin] = useState<Admin>({ uuid: "", name: "" });
  const [account, setAccount] = useState<Account>();
  const [homes, setHomes] = useState<Home[]>();
  const [home, setHome] = useState<Home>();
  const [stats, setStats] = useState<StatsAndMergedSummary>();

  const [passwordResetToken, setPasswordResetToken] = useState<string>();

  const [invitationToken, setInvitationToken] = useState<string>();
  const resetInvitationToken = () => {
    setInvitationToken(undefined);
  };

  const [path, setPath] = useState<string>("/");

  const [loaderState, loader] = useLoader();

  const navigate = useNavigate();

  ///////////////////////////////////////////////////////////////////////////

  const defaultProps: DefaultProps = {
    path,
    loader,
    navigate,
    admin,
    setAdmin,
    account,
    setAccount,
    home,
    setHome,
    homes,
    setHomes,
    stats,
    setStats,
  };

  const authProps: AuthProps = {
    onSignedOut: () => {},
    invitationToken,
    resetInvitationToken,
    passwordResetToken,
  };

  const defaultAndAuthProps: DefaultAndAuthProps = {
    ...defaultProps,
    ...authProps,
  };

  ///////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const path = start_path;
    const token = start_token;

    if (window.location.hostname !== "localhost")
      window.history.pushState("url-rewrite", document.title, path);

    switch (path) {
      case Paths.RESET_PASSWORD:
        setPasswordResetToken(token);
        return;
      default:
        break;
    }

    const accessToken = retrieveAccessToken();
    if (accessToken) {
      signInWithAccessToken(accessToken, defaultAndAuthProps);
      return;
    }

    navigate(Paths.SIGNIN);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
  }, [admin]);

  useEffect(() => {
    setHome(undefined);
    setHomes(undefined);
    setStats(undefined);

    if (admin?.access_token && account) {
      actionLoadHomes();
    }
    // eslint-disable-next-line
  }, [account]);

  useEffect(() => {
    if (homes && homes.length) setHome(homes[0]);
    // eslint-disable-next-line
  }, [homes]);

  useEffect(() => {
    setPath(window.location.pathname);
    // eslint-disable-next-line
  }, [navigate]);

  ///////////////////////////////////////////////////////////////////////////

  const actionLoadHomes = () => {
    apiGetHomesList(admin, account?.uuid)
      .then((response) => {
        const homes: Home[] = response.data;
        setHomes(homes);
      })
      .catch((error) => {
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  ///////////////////////////////////////////////////////////////////////////

  return (
    <div className={css.app}>
      <TopMenu {...defaultProps} {...authProps}></TopMenu>

      <div className={css.content}>
        <Routes>
          <Route
            path={Paths.SIGNIN}
            element={<LoginScreen {...defaultProps} {...authProps} />}
          />

          <Route
            path={Paths.RESET_PASSWORD}
            element={<PasswordResetScreen {...defaultProps} {...authProps} />}
          />

          <Route
            path={Paths.ACCOUNT}
            element={<AccountScreen {...defaultProps} {...authProps} />}
          />
          <Route
            path={Paths.HOMES}
            element={<HomesScreen {...defaultProps} {...authProps} />}
          />
          <Route
            path={Paths.STAFF}
            element={<StaffScreen {...defaultProps} {...authProps} />}
          />
          <Route
            path={Paths.USERS}
            element={<UsersScreen {...defaultProps} {...authProps} />}
          />
          <Route
            path={Paths.CALENDAR_AGENDA}
            element={<CalendarScreen {...defaultProps} />}
          />
          <Route path={Paths.STATISTICS} element={<StatsScreen {...defaultProps} />} />
          <Route path="*" element={<Navigate to={Paths.SIGNIN} />} />
        </Routes>
      </div>

      {loader.show && <Loader {...loaderState}></Loader>}
    </div>
  );
}

export default App;
