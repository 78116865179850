import { getLanguageString as _ } from "misc/lang";
import { LoaderUtil } from "misc/loader";
import {
  apiTriggerPasswordReset,
  apiSignInAccessToken,
  apiSignInEmailPassword,
  apiGetAccount,
} from "net/requests";
import { LS_ACCESS_TOKEN, Paths } from "appConfig";
import { DefaultAndAuthProps } from "model/props";
import { Admin } from "model/admin";

var ls = require("local-storage");

export const triggerPasswordReset = (
  email: string,
  loader: LoaderUtil,
  setError?: (error: string) => void
) => {
  loader.setLoading();
  apiTriggerPasswordReset(email)
    .then((response) => {
      loader.setResult(
        true,
        _("auth", "trigger_pw_reset_head"),
        _("auth", "trigger_pw_reset_response")
      );
    })
    .catch((error) => {
      loader.reset();
      const errorCode = error.response?.data?.error;
      if (errorCode === "rate-limit-block") {
        setError && setError(_("auth", "trigger_pw_reset_error_rate_limit"));
      } else if (errorCode === "rate-limit-block-24h") {
        setError && setError(_("auth", "trigger_pw_reset_error_rate_limit_24h"));
      } else {
        setError && setError(_("auth", "trigger_pw_reset_error"));
      }
    });
};

export const loadMyAccount = (props: DefaultAndAuthProps, admin: Admin) => {
  const { setAccount } = props;

  apiGetAccount(admin)
    .then((response) => {
      setAccount(response.data);
    })
    .catch((error) => {
      setAccount();
    });
};

export const signInWithEmailPassword = (
  email: string,
  password: string,
  loader: LoaderUtil,
  props: DefaultAndAuthProps,
  setError?: (error: string) => void
) => {
  loader.setLoading();
  apiSignInEmailPassword(email, password)
    .then((response) => {
      loader.reset();
      onSignedIn(props, response.data);
    })
    .catch((error) => {
      loader.reset();
      removeAccessToken();
      const errorCode = error.response?.data?.error;
      if (errorCode === "rate-limit-block") {
        setError && setError(_("auth", "login_error_rate_limit"));
      } else if (errorCode === "rate-limit-block-24h") {
        setError && setError(_("auth", "login_error_rate_limit_24h"));
      } else {
        setError && setError(_("auth", "login_error"));
      }
    });
};

export const signInWithAccessToken = (
  accessToken: string,
  props: DefaultAndAuthProps
) => {
  const { navigate } = props;
  apiSignInAccessToken(accessToken)
    .then((response) => {
      onSignedIn(props, response.data);
    })
    .catch((error) => {
      removeAccessToken();
      navigate(Paths.SIGNIN);
      console.log(error.response?.data?.error);
    });
};

export const onSignedIn = (props: DefaultAndAuthProps, admin: Admin) => {
  const { setAdmin, navigate } = props;
  storeAccessToken(admin.access_token);

  setAdmin && setAdmin(admin);

  if (admin.is_super_admin) {
    navigate(Paths.ACCOUNT);
  } else {
    loadMyAccount(props, admin);
    if (admin.is_account_admin) {
      navigate(Paths.HOMES);
    } else {
      navigate(Paths.USERS);
    }
  }
};

export const signOut = (props: DefaultAndAuthProps) => {
  const { setAccount, setAdmin, navigate } = props;
  setAccount(undefined);
  setAdmin({ uuid: "", name: "" });
  removeAccessToken();
  navigate(Paths.SIGNIN);
};

export const storeAccessToken = (accessToken?: string) => {
  ls.set(LS_ACCESS_TOKEN, accessToken || "");
};

export const retrieveAccessToken = (): string | undefined => {
  return ls.get(LS_ACCESS_TOKEN);
};

export const removeAccessToken = () => {
  ls.remove(LS_ACCESS_TOKEN);
};

const defaults = {
  triggerPasswordReset,
  storeAccessToken,
  retrieveAccessToken,
  removeAccessToken,
  signInWithAccessToken,
  signInWithEmailPassword,
  signOut,
};

export default defaults;
