import React, { useState, useEffect } from "react";
import { getLanguageString as _ } from "misc/lang";

import { DefaultAndAuthProps } from "model/props";
import {
  apiAssignManager,
  apiGetManagerList,
  apiHomeCreate,
  apiHomeDelete,
  apiHomeUpdate,
  apiHomeUpdateSigninQR,
  apiRemoveManager,
} from "net/requests";
import SearchableList from "components/listeditor/SearchableList";
import { deleteLocal, matchItem, saveLocal } from "misc/list_helper";

import Editor from "components/listeditor/Editor";
import { Admin } from "model/admin";
import AdminList from "components/adminlist/AdminList";

import cssLayout from "../layout.module.scss";
import css from "./homesscreen.module.scss";
import cssQrPopup from "../qrpopup.module.scss";

import FormButton from "components/form/FormButton";
import { Home, HomeFields } from "model/home";
import { getQRImageDataURL, savePNGFile } from "misc/qr_utils";

const HomesScreen: React.FC<DefaultAndAuthProps> = (props) => {
  const { admin, account, homes, setHomes, home, setHome, loader } = props;

  const [editorItem, updateEditorItem] = useState<Home>();
  const [operationManagers, setOperationManagers] = useState<Admin[]>([]);
  const [homeManagers, setHomeManagers] = useState<Admin[]>([]);
  const [error, setError] = useState<string>();

  const [qrImgData, setQrImgData] = useState<string>();
  const [showQr, setShowQr] = useState<boolean>(false);

  /////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    setOperationManagers([]);
    setHomeManagers([]);

    if (home) {
      updateEditorItem({ ...home });
      if (admin.is_super_admin || admin.is_account_admin)
        actionLoadManager("operation", setOperationManagers);
      if (admin.is_super_admin || admin.is_account_admin || admin.is_operations_manager)
        actionLoadManager("home", setHomeManagers);

      const imageData = getQRImageDataURL(home?.signin_code || "qr-kod", true, 10);
      setQrImgData(imageData);
    } else {
      updateEditorItem(undefined);
    }
    // eslint-disable-next-line
  }, [home]);
  /////////////////////////////////////////////////////////////////////////

  const actionLoadManager = (type: string, fnSet: (list: Admin[]) => void) => {
    if (!home) return;

    apiGetManagerList(admin, home.uuid, type)
      .then((response) => {
        fnSet && fnSet(response.data);
      })
      .catch((error) => {
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  /////////////////////////////////////////////////////////////////////////

  const actionCancel = () => {
    if (home) updateEditorItem({ ...home });
  };

  const actionNew = () => {
    if (!account || !homes) return;

    loader.setLoading();
    apiHomeCreate(admin, { account_uuid: account.uuid })
      .then((response) => {
        loader.reset();
        setHomes([...homes, response.data]);
        setHome(response.data);
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  const actionSave = () => {
    if (!editorItem || !home || !homes) return;

    loader.setLoading();
    apiHomeUpdate(admin, home.uuid, editorItem)
      .then((response) => {
        loader.reset();
        saveLocal(homes, editorItem, setHomes);
        setHome(editorItem);
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  const actionDelete = () => {
    if (!home || !homes) return;

    loader.setLoading();
    apiHomeDelete(admin, home.uuid)
      .then((response) => {
        loader.reset();
        deleteLocal(homes, home.uuid, setHomes);
        setHome(undefined);
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  /////////////////////////////////////////////////////////////////////////

  const actionAssignManager = (email: string, type: string) => {
    if (!home) return;

    loader.setLoading();
    apiAssignManager(admin, home.uuid, type, email)
      .then((response) => {
        loader.reset();
        const new_admin: Admin = response.data;

        if (type === "home") {
          saveLocal(homeManagers, new_admin, setHomeManagers);
        } else if (type === "operation") {
          saveLocal(operationManagers, new_admin, setOperationManagers);
        }
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
        if (errorCode === "conflict") {
          setError(_("homes", "error_conflict"));
        }
      });
  };

  const actionRemoveManager = (adminToRemove: Admin, type: string) => {
    if (!home) return;
    if (
      !window.confirm(
        _("homes", "confirm_remove_manager_" + type) + adminToRemove.email_address
      )
    )
      return;

    loader.setLoading();
    apiRemoveManager(admin, home.uuid, type, adminToRemove.uuid)
      .then((response) => {
        loader.reset();

        if (type === "home") {
          deleteLocal(homeManagers, adminToRemove.uuid, setHomeManagers);
        } else if (type === "operation") {
          deleteLocal(operationManagers, adminToRemove.uuid, setOperationManagers);
        }
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  /////////////////////////////////////////////////////////////////////////

  const actionUpdateSigninQR = () => {
    if (!home || !homes) return;

    loader.setLoading();
    apiHomeUpdateSigninQR(admin, home?.uuid)
      .then((response) => {
        loader.reset();
        saveLocal(homes, response.data, setHomes);
        setHome(response.data);
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  /////////////////////////////////////////////////////////////////////////

  const qrPopup = (
    <div className={cssQrPopup.popupWrapper}>
      <div className={cssQrPopup.popup}>
        <div className={cssQrPopup.heading}>{home?.name || ""}</div>
        <img className={cssQrPopup.qrCode} src={qrImgData} alt="QR"></img>
        <div className={cssQrPopup.buttons}>
          <FormButton
            label={_("account", "btn_regenerate_qr")}
            onSubmit={(event) => actionUpdateSigninQR()}
            variant={"outlined"}
          />
          <FormButton
            label={_("general", "btn_print")}
            onSubmit={(event) => window.print()}
            variant={"outlined"}
          />
          <FormButton
            label={_("general", "btn_download")}
            onSubmit={() =>
              home && savePNGFile(home.signin_code, home.name || "qr", true)
            }
            variant={"outlined"}
          />
          <FormButton
            label={_("general", "btn_close")}
            onSubmit={(event) => setShowQr(false)}
            variant={"outlined"}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className={cssLayout.screen}>
        <div className={cssLayout.pageHeading}>
          {(account?.name || "") + " - " + _("homes", "page_heading")}
        </div>
        <div className={css.homes}>
          <div className={cssLayout.listeditor}>
            <div className={cssLayout.list}>
              <SearchableList
                items={homes || []}
                matchItem={matchItem}
                onSelect={setHome}
                selected={home}
                onAddNew={
                  ((admin.is_super_admin || admin.is_account_admin) && actionNew) ||
                  undefined
                }
              ></SearchableList>
            </div>
            <div className={cssLayout.editor}>
              {home && (
                <>
                  <>
                    {editorItem && (
                      <Editor
                        item={editorItem}
                        updateItem={updateEditorItem}
                        fields={HomeFields}
                        string_group={"homes"}
                        onDelete={
                          ((admin.is_super_admin || admin.is_account_admin) &&
                            actionDelete) ||
                          undefined
                        }
                        onSave={actionSave}
                        onCancel={actionCancel}
                        secondaryAction={() => setShowQr(true)}
                        secondaryActionLabel={_("general", "btn_show_qr")}
                      />
                    )}
                    {!home && _("homes", "select_account_from_list")}
                  </>
                  {(admin.is_super_admin || admin.is_account_admin) &&
                    operationManagers && (
                      <>
                        <AdminList
                          heading={_("homes", "operation_manager_heading")}
                          admins={operationManagers}
                          onDelete={(admin: Admin) =>
                            actionRemoveManager(admin, "operation")
                          }
                          onAssign={(email: string) =>
                            actionAssignManager(email, "operation")
                          }
                          string_group={"homes"}
                          id={"operation_manager"}
                        />
                        <div className={css.error}>{error}</div>
                      </>
                    )}
                  {(admin.is_super_admin ||
                    admin.is_account_admin ||
                    admin.is_operations_manager) &&
                    homeManagers && (
                      <>
                        <AdminList
                          heading={_("homes", "home_manager_heading")}
                          admins={homeManagers}
                          onDelete={(admin: Admin) =>
                            actionRemoveManager(admin, "home")
                          }
                          onAssign={(email: string) =>
                            actionAssignManager(email, "home")
                          }
                          string_group={"homes"}
                          id={"home_manager"}
                        />
                        <div className={css.error}>{error}</div>
                      </>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {home && showQr && qrPopup}
    </>
  );
};

export default HomesScreen;
