import React from "react";

import { getLanguageString as _ } from "misc/lang";
import { classNames } from "misc/helpers";

import css from "./activitytable.module.scss";

import { ActivityMap, StaffMap } from "model/stats";
import { CalendarActivity } from "model/calendar";
import { formatTimestampUnix } from "misc/date_utils";
import { getObjectiveColor } from "misc/stats_util";
import { ACTIVITY_NOT_PERFORMED, ACTIVITY_PERFORMED, ACTIVITY_UNABLE } from "appConfig";

export interface Props {
  staffMap?: StaffMap;
  activityMap?: ActivityMap;
  period: number;
  data: CalendarActivity[][];
  objectiveId?: number;
}

const ActivityTable: React.FC<Props> = (props) => {
  const { staffMap, activityMap, period, data, objectiveId } = props;

  const activityData = (period >= 0 && data[period]) || data;

  const renderRow = (
    data: CalendarActivity[][] | CalendarActivity[] | CalendarActivity
  ): JSX.Element => {
    if (Array.isArray(data)) {
      return <>{data.map((item) => renderRow(item))}</>;
    }

    const activity = activityMap && activityMap[data.activity];

    if (objectiveId && activity?.objective !== objectiveId) return <></>;

    const staff = staffMap && staffMap[data.staff_uuid];

    const performed = data.status === ACTIVITY_PERFORMED;
    const unable = !performed && data.status === ACTIVITY_UNABLE;
    const not_performed =
      !performed && !unable && data.status === ACTIVITY_NOT_PERFORMED;

    const className =
      (performed && css.performed) ||
      (unable && css.unable) ||
      (not_performed && css.not_performed) ||
      undefined;

    const styleColor = {
      backgroundColor:
        (activity && getObjectiveColor(activity.objective_color)) || "inherit",
    };
    return (
      <tr key={data.activity + "_" + data.date + "" + data.status}>
        <td>{formatTimestampUnix(data.date)}</td>
        <td>{(activity && activity.title) || ""}</td>
        <td>
          <div className={css.objective}>
            <div className={css.color} style={styleColor}></div>
            {(activity && activity.objective_title) || ""}
            {/* <div className={css.color} style={styleColor}></div> */}
          </div>
        </td>
        <td>
          <div className={css.status}>
            <div className={className}></div>
            {_("stats", data.status)}
          </div>
        </td>
        <td>{(staff && staff.identifier) || ""}</td>
      </tr>
    );
  };

  return (
    <table className={css.activity_table}>
      <thead>
        <tr>
          <th>Datum</th>
          <th>Aktivitet</th>
          <th>Mål</th>
          <th>Status</th>
          <th>Signatur</th>
        </tr>
      </thead>
      <tbody>{activityData.map((adata) => renderRow(adata))}</tbody>
    </table>
  );
};

export default ActivityTable;
