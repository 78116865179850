var humanFormat = require("human-format");

export const readToken = (): string => {
  const hash = window.location.hash;
  const token = hash && hash.length > 0 ? hash.substring(1) : "";
  if (window.location.hostname !== "localhost") window.location.hash = "";
  return token;
};

export const replaceBrowserUrl = (path: string = "") => {
  window.history.replaceState(null, "", path);
};

export const readPath = (): string => {
  return window.location.pathname;
};

export const copyTextToClipboard = (text: string): boolean => {
  var elem = document.createElement("textarea");
  elem.value = text;
  elem.style.width = "0";
  elem.style.height = "0";
  elem.style.top = "0";
  elem.style.left = "0";
  elem.style.position = "fixed";
  document.body.appendChild(elem);
  elem.focus();
  elem.select();
  elem.setSelectionRange(0, 99999);

  var successful = false;
  try {
    successful = document.execCommand("copy");
  } catch (err) {}

  document.execCommand("copy");
  document.body.removeChild(elem);

  return successful;
};

export const humanReadable = (number: number): any => {
  const magnitude = Math.floor(Math.log10(number));
  const siMagnitude = magnitude % 3; //magnitude when using human readable K, M, G, etc
  const siDecimals = 2 - siMagnitude;
  return humanFormat(number, { decimals: siDecimals });
};

export const classNames = (
  class1: string,
  class2?: string | false | undefined,
  class3?: string | false | undefined,
  class4?: string | false | undefined
): any => {
  return (
    class1 +
    (class2 ? " " + class2 : "") +
    (class3 ? " " + class3 : "") +
    (class4 ? " " + class4 : "")
  );
};

const defaults = {
  readToken,
  replaceBrowserUrl,
  copyTextToClipboard,
  humanReadable,
  classNames,
};

export default defaults;
