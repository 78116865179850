import {
  CalendarAgenda,
  CalendarPersonAgenda,
  CalendarPersonAgendaMap,
} from "model/calendar";
import { getDateOffsetJSDates, getTimeString, unixtime2JSDate } from "./date_utils";

const getOrCreatePersonAgenda = (
  uuid: string,
  identifier: string,
  personAgenda: CalendarPersonAgenda[],
  personAgendaMap: CalendarPersonAgendaMap,
  days: number
) => {
  if (!(uuid in personAgendaMap)) {
    const cpa: CalendarPersonAgenda = {
      user: uuid,
      user_identifier: identifier,
      days: [],
      max_activites_per_hour: [],
    };

    for (let i = 0; i < days; i++) {
      cpa.days.push(getEmptyArray24());
      cpa.max_activites_per_hour.push(0);
    }

    personAgenda.push(cpa);
    personAgendaMap[uuid] = cpa;
  }

  const cpa: CalendarPersonAgenda = personAgendaMap[uuid];
  return cpa;
};

const getEmptyArray24 = () => {
  return [
    [], //0
    [], //1
    [], //2
    [], //3
    [], //4
    [], //5
    [], //6
    [], //7
    [], //8
    [], //9
    [], //10
    [], //11
    [], //12
    [], //13
    [], //14
    [], //15
    [], //16
    [], //17
    [], //18
    [], //19
    [], //20
    [], //21
    [], //22
    [], //23
  ];
};

export const processCalendarAgenda = (
  agendaList: CalendarAgenda[],
  firstDate: Date,
  days: number
): CalendarPersonAgenda[] => {
  const personAgenda: CalendarPersonAgenda[] = [];
  const personAgendaMap: CalendarPersonAgendaMap = {};

  for (let i = 0; i < agendaList.length; i++) {
    const item = agendaList[i];

    const cpa: CalendarPersonAgenda = getOrCreatePersonAgenda(
      item.user,
      item.user_identifier,
      personAgenda,
      personAgendaMap,
      days
    );

    const date = unixtime2JSDate(item.activity_timestamp);
    item.activity_time_float = date.getHours() + date.getMinutes() / 60;
    item.activity_time_string = getTimeString(date);
    const hour = Math.floor(item.activity_time_float);

    if (item.activity_duration_minutes)
      item.activity_duration_hours_float = item.activity_duration_minutes / 60;

    const dateOffset = getDateOffsetJSDates(firstDate, date);

    cpa.days[dateOffset][hour].push(item);
    const concurrentCount = cpa.days[dateOffset][hour].length;
    if (concurrentCount > cpa.max_activites_per_hour[dateOffset]) {
      cpa.max_activites_per_hour[dateOffset] = concurrentCount;
    }
  }

  return personAgenda;
};
