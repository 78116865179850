import React, { useState, useRef, useEffect } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";

import { getLanguageString as _ } from "misc/lang";
import { getLanguageStringArray as _ar } from "misc/lang";
import { Stats } from "model/stats";

import css from "./twograph.module.scss";
import {
  COLOR_GRAY,
  COLOR_GREEN,
  COLOR_RED,
  COLOR_YELLOW,
  PERCENT_RED,
  PERCENT_YELLOW,
} from "appConfig";
import { getStatus } from "misc/stats_util";

export interface Props {
  period?: string;
  summary: Stats;
  months: Stats[];
}

const TwoGraph: React.FC<Props> = (props) => {
  const { period, summary, months } = props;

  const [doughnutData, setDoghnutData] = useState<any>();
  const [lineData, setLineData] = useState<any>();

  const ref = useRef();

  ChartJS.register(CategoryScale, ArcElement, LinearScale, PointElement, LineElement);

  useEffect(() => {
    const percent = summary.percent_performed || 0;

    // DOUGHNUT
    const color = getStatus(percent, COLOR_RED, COLOR_YELLOW, COLOR_GREEN);

    const data1 = {
      datasets: [
        {
          data: [percent, 100 - percent],
          backgroundColor: [color, COLOR_GRAY],
          hoverOffset: 0,
          offset: 0,
        },
      ],
    };
    setDoghnutData(data1);

    // LINE

    const data2 = {
      labels: _ar("general", "month_names"),
      datasets: [
        {
          data: months.map((m) => m.percent_performed),
          fill: false,
          borderColor: "#2d6773",
          tension: 0.1,
        },
      ],
    };
    setLineData(data2);

    // eslint-disable-next-line
  }, [summary]);

  return (
    <div className={css.twograph}>
      <div className={css.summary}>
        {doughnutData && (
          <>
            {period && <div className={css.period}>{period}</div>}
            <div className={css.chart}>
              <Doughnut
                ref={ref}
                datasetIdKey="summary"
                data={doughnutData}
                options={doughnutOptions}
              />
            </div>
            <div className={css.info}>
              {(summary.percent_performed || 0) +
                "% " +
                _("stats", "performed_activites")}
              <div className={css.line2}>
                {(summary.count_total || 0) + " " + _("stats", "total_activities")}
              </div>
            </div>
            <div className={css.legend}>
              <div className={css.item}>
                <div className={css.red}></div>
                <div className={css.text}>0-{PERCENT_RED}%</div>
              </div>
              <div className={css.item}>
                <div className={css.yellow}></div>
                <div className={css.text}>
                  {PERCENT_RED}-{PERCENT_YELLOW}%
                </div>
              </div>
              <div className={css.item}>
                <div className={css.green}></div>
                <div className={css.text}>{PERCENT_YELLOW}-100%</div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={css.monthly}>
        {lineData && (
          <div className={css.chart}>
            <Line
              ref={ref}
              datasetIdKey="months"
              data={lineData}
              options={lineOptions}
            />
          </div>
        )}
      </div>
      <div className={css.year}></div>
    </div>
  );
};

const doughnutOptions = {
  rotation: -90,
  circumference: 180,
  legend: {
    display: false,
  },
  tooltip: {
    enabled: false,
  },
  events: [],
  cutoutPercentage: 100,
  animation: { duration: 0 },
};

const lineOptions = {
  scales: {
    y: {
      min: 0,
    },
  },
  maintainAspectRatio: false,
  animation: { duration: 0 },
};

export default TwoGraph;
