import qrcode from "qrcode-generator";
import { saveBlobToFile } from "./utils";

const QR_LARGE = 24;
const QR_SMALL = 5;

const getQR = (data: string) => {
  var qr = qrcode(0, "L");
  qr.addData(data);
  qr.make();
  return qr;
};

export const getQRImageDataURL = (
  data: string,
  margin: boolean = true,
  size: number = QR_SMALL
): string => {
  const qr = getQR(data);
  return qr.createDataURL(size, (margin && size) || 0);
};

export const saveSVGFile = (data: string, name: string) => {
  const qr = getQR(data);
  const svgContent = qr.createSvgTag(QR_SMALL, QR_SMALL);
  const blob = new Blob([svgContent], { type: "image/svg+xml" });
  const filename = name + ".svg";
  saveBlobToFile(blob, filename);
};

const generatePNG = (imgSrc: any, onLoaded: (data: any) => void) => {
  var img = new Image();
  img.onload = function () {
    const size = img.width;
    const canvas: any = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext("2d");
    ctx && ctx.drawImage(img, 0, 0);

    if (canvas.toBlob) {
      canvas.toBlob(onLoaded, "image/png");
    } else if (canvas.msToBlob) {
      canvas.msToBlob(onLoaded, "image/png");
    }
  };
  img.src = imgSrc;
};

export const savePNGFile = (data: string, name: string, large: boolean = false) => {
  const imageDataURL = getQRImageDataURL(data, true, large ? QR_LARGE : QR_SMALL);

  const save = (blob: any) => {
    const filename = name + ".png";
    saveBlobToFile(blob, filename);
  };

  generatePNG(imageDataURL, save);
};

const defaults = {
  getQRImageDataURL,
  saveSVGFile,
  savePNGFile,
};

export default defaults;
