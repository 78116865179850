export const account = {
  page_heading: "Kundkonto",
  editor_label_name: "Namn",
  editor_label_org_number: "Organisationsnummer",
  editor_label_address_line1: "Adress",
  editor_label_address_line2: "Adress, rad 2",
  editor_label_address_postal_code: "Postnummer",
  editor_label_address_region: "Ort",

  account_admin_heading: "Processägare - Behörighet 1",
  number_admins_assigned: "person(er)",
  account_admin_add_placeholder: "Lägg till ny, ange e-postadress",

  btn_homes: "Se boenden",

  btn_regenerate_qr: "Generera ny QR-kod",
};
