import { Admin } from "./admin";
import { Home } from "./home";
import { User } from "./user";

export interface Stats {
  count_total: number;
  count_performed: number;
  count_unable_to_perform: number;
  percent_performed: number;

  count_wellbeing_happy?: number;
  count_wellbeing_sad?: number;
  count_wellbeing_neutral?: number;
}

////////////////////////////////////////////////////////////////////

export interface Activity {
  id: number;
  title: string;
  objective: number;
  objective_title: string;
  objective_color: string;
}
export type ActivityMap = { [id: number]: Activity };

export interface Staff {
  uuid: number;
  identifier: string;
}
export type StaffMap = { [uuid: string]: Staff };
////////////////////////////////////////////////////////////////////
export interface MonthlyStatsAndSummary {
  months: Stats[];
  summary: Stats; // calculated locally
}

export interface HomeStats extends MonthlyStatsAndSummary {
  home: Home;
  home_managers?: Admin[];
  operation_manager?: Admin[];
  user_count?: number;
}

export interface UserStats extends MonthlyStatsAndSummary {
  user: User;
}

export interface ActivityStats extends MonthlyStatsAndSummary {
  activity: number;
  activity_title: string;
  objective: number;
  objective_title: string;
  objective_color: string;
}

export interface StaffSignOffStats extends MonthlyStatsAndSummary {
  staff_uuid: number;
  staff_identifier: string;
  objective: number;
  active_days: number[];
  active_days_total: number;
}

////////////////////////////////////////////////////////////////////

export interface StatsAndMergedSummary {
  stats: MonthlyStatsAndSummary[];
  months: Stats[];
  summary: Stats;
}

export interface ObjectiveStatsAndMergedSummary extends StatsAndMergedSummary {
  id: number;
  title: string;
  color: string;
}

////////////////////////////////////////////////////////////////////
export interface Wellbeing {
  timestamp?: string;
  unix_timestamp: number;
  wellbeing: string;
}
export interface WellbeingSummary {
  happy: number;
  neutral: number;
  sad: number;
  total: number;
}

export const newWellbeingSummary = (): WellbeingSummary => {
  return { happy: 0, neutral: 0, sad: 0, total: 0 };
};
