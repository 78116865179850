import React from "react";
import Logo from "assets/images/addyou.png";
import Button from "@mui/material/Button";

import { LoaderState } from "misc/loader";

import { getLanguageString as _ } from "misc/lang";

import css from "./loader.module.scss";

const Loader: React.FC<LoaderState> = (props) => {
  const { loading, heading, text, action, loader } = props;

  /////////////////////////////////////////////////////////////////////////

  const _action = () => {
    loader?.reset();
    action && action();
  };

  return (
    <div className={css.background}>
      <div className={css.content}>
        {loading ? (
          <div className={css.spinnerWrapper}>
            <img className={css.spinner} src={Logo} alt="Loading" />
            <div className={css.spinnerText}>{_("general", "loading")}</div>
          </div>
        ) : (
          <div className={css.resultWrapper}>
            <div className={css.heading}>{heading}</div>
            <div className={css.desc}>{text}</div>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="small"
              className={css.continueButton}
              onClick={_action}
            >
              {action ? _("general", "btn_continue") : _("general", "btn_close")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Loader;
