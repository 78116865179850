import React from "react";

import css from "./form.module.scss";
import { Button } from "@mui/material";

export interface Props {
  key?: string;
  label?: string;
  disabled?: boolean;
  onSubmit: (event: any) => void;
  variant?: "contained" | "text" | "outlined" | undefined;
  inline?: boolean;
  color?:
    | "primary"
    | "inherit"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  size?: "medium" | "small" | "large";
  startIcon?: any;
  endIcon?: any;
}

const FormButton: React.FC<Props> = (props) => {
  const {
    key,
    label,
    disabled,
    onSubmit,
    variant = "contained",
    inline = false,
    color = "primary",
    size = "medium",
    startIcon = undefined,
    endIcon = undefined,
  } = props;

  /////////////////////////////////////////////////////////////////////////

  return (
    <Button
      key={key}
      fullWidth={!inline}
      type="submit"
      className={inline ? css.buttonInline : css.button}
      variant={variant}
      color={color}
      size={size}
      onClick={onSubmit}
      disabled={!!disabled}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {label}
    </Button>
  );
};

export default FormButton;
