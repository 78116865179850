import React, { useState, useRef, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";

import { getLanguageString as _ } from "misc/lang";
import { getLanguageStringArray as _ar } from "misc/lang";

import css from "./wellbeing.module.scss";

import { incrementWellbeingSummary } from "misc/stats_util";
import {
  Wellbeing as WellbeingData,
  WellbeingSummary as WellbeingSummaryData,
  newWellbeingSummary,
} from "model/stats";
import { COLOR_GRAY, COLOR_GREEN, COLOR_YELLOW, COLOR_RED } from "appConfig";
import { Props as WellbeingProps } from "./Wellbeing";
import { formatString } from "misc/utils";

const WellbeingSummary: React.FC<WellbeingProps> = (props) => {
  const { month, data, yearSummary } = props;

  const [monthSummary, setMonthSummary] = useState<WellbeingSummaryData>();

  const summary = month < 0 ? yearSummary : monthSummary;

  const ref = useRef();

  const doughnutData =
    (summary?.total || 0) > 0
      ? {
          datasets: [
            {
              data: [summary?.happy, summary?.neutral, summary?.sad],
              backgroundColor: [COLOR_GREEN, COLOR_YELLOW, COLOR_RED],
              hoverOffset: 0,
              offset: 0,
            },
          ],
        }
      : {
          datasets: [
            {
              data: [100],
              backgroundColor: [COLOR_GRAY],
              hoverOffset: 0,
              offset: 0,
            },
          ],
        };

  /////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (month < 0) return;

    const monthSum: WellbeingSummaryData = newWellbeingSummary();
    const monthData: WellbeingData[][] = data[month];

    for (let d = 0; d < 31; d++) {
      const dayData = monthData[d];
      for (let i = 0; i < dayData.length; i++)
        incrementWellbeingSummary(monthSum, dayData[i]);
    }
    setMonthSummary(monthSum);
    // eslint-disable-next-line
  }, [month, data]);

  /////////////////////////////////////////////////////////////////////////

  const getLegendItem = (
    stringName: string,
    cssClass: any,
    count: number,
    total: number
  ) => {
    return (
      <div className={cssClass}>
        <div className={css.legend}>
          <div className={css.dot}></div>
          <div className={css.title}>{_("wellbeing", stringName)}</div>
        </div>
        <div className={css.info}>
          {formatString(
            _("wellbeing", "legend_info"),
            count,
            total > 0 ? Math.round((count * 100) / total) : "0"
          )}
        </div>
      </div>
    );
  };

  const month_name = _ar("general", "month_names");
  const periodString = month < 0 ? _("stats", "whole_year") : month_name[month];

  return (
    <div className={css.wellbeing_summary}>
      <div className={css.head}>
        <div className={css.title}>{periodString}</div>
      </div>
      <div className={css.chart_wrapper}>
        <div className={css.chart}>
          <Doughnut
            ref={ref}
            datasetIdKey="summary"
            data={doughnutData}
            options={doughnutOptions}
          />
        </div>
      </div>
      <div className={css.chart_legend}>
        {summary && getLegendItem("happy", css.happy, summary.happy, summary.total)}
        {summary &&
          getLegendItem("neutral", css.neutral, summary.neutral, summary.total)}
        {summary && getLegendItem("sad", css.sad, summary.sad, summary.total)}
      </div>
    </div>
  );
};

const doughnutOptions = {
  rotation: 0,
  circumference: 360,
  legend: {
    display: false,
  },
  tooltip: {
    enabled: false,
  },
  events: [],
  cutoutPercentage: 100,
  animation: { duration: 0 },
};

export default WellbeingSummary;
