import React from "react";
import { getLanguageString as _ } from "misc/lang";

import TextInput from "components/form/TextInput";
import { Listable } from "model/data";

import css from "./listeditor.module.scss";
import FormButton from "components/form/FormButton";

export interface Props {
  item: Listable;
  updateItem: (item: any) => void;
  fields: string[];
  getLabel?: (item: any) => string;
  string_group: string;
  onCancel?: () => void;
  onSave?: () => void;
  onDelete?: () => void;
  secondaryActionLabel?: string;
  secondaryAction?: () => void;
}

const Editor: React.FC<Props> = (props) => {
  const {
    item,
    updateItem,
    fields,
    getLabel,
    string_group,
    onCancel,
    onSave,
    onDelete,
    secondaryAction,
    secondaryActionLabel,
  } = props;

  /////////////////////////////////////////////////////////////////////////

  const updateValue = (key: string, value: string) => {
    const newItem = { ...item };
    newItem[key] = value;
    updateItem(newItem);
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
  };

  const _onDelete = () => {
    const ok = window.confirm(
      _("general", "confirm_delete") + ((getLabel && getLabel(item)) || item.name)
    );
    if (ok) onDelete && onDelete();
  };

  /////////////////////////////////////////////////////////////////////////

  return (
    <div className={css.editor}>
      <form onSubmit={onSubmit}>
        {fields.map((key) => {
          return (
            <TextInput
              key={key}
              label={_(string_group, "editor_label_" + key)}
              initialValue={(item[key] || "") as string}
              updateValue={(newValue) => updateValue(key, newValue)}
            />
          );
        })}

        <div className={css.buttons}>
          <div className={css.delete}>
            {onDelete && (
              <FormButton
                label={_("general", "btn_delete")}
                onSubmit={(event) => _onDelete()}
                variant={"outlined"}
                inline={true}
                disabled={!onDelete}
                color={"warning"}
              />
            )}
            {secondaryAction && (
              <FormButton
                label={secondaryActionLabel}
                onSubmit={(event) => secondaryAction && secondaryAction()}
                variant={"contained"}
                inline={true}
                color={"primary"}
              />
            )}
          </div>
          <div className={css.edit}>
            <FormButton
              label={_("general", "btn_reset")}
              onSubmit={(event) => onCancel && onCancel()}
              variant={"outlined"}
              inline={true}
            />
            <FormButton
              label={_("general", "btn_save")}
              onSubmit={(event) => onSave && onSave()}
              inline={true}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Editor;
