import React, { useState, useRef, useEffect } from "react";

import { getLanguageString as _, getLanguageStringArray } from "misc/lang";
import { getLanguageStringArray as _ar } from "misc/lang";

import css from "./wellbeing.module.scss";

import { Wellbeing } from "model/stats";
import { WELLBEING_HAPPY, WELLBEING_NEUTRAL, WELLBEING_SAD } from "appConfig";
import { getWeekday } from "misc/date_utils";
import { Props as WellbeingProps } from "./Wellbeing";

const LOAD_DAYS = 7;

export interface Props extends WellbeingProps {
  daySelected?: number | undefined;
  setDaySelected?: (day: number | undefined) => void;
}

const WellbeingCalendar: React.FC<Props> = (props) => {
  const { year, month, data, daySelected, setDaySelected } = props;

  const firstDateOfMonth = (month >= 0 && new Date(year, month, 1)) || 0;
  const firstWeekdayOfMonth = (firstDateOfMonth && getWeekday(firstDateOfMonth)) || 0;
  const daysOfMonth = (month >= 0 && new Date(year, month + 1, 0).getDate()) || 0;

  /////////////////////////////////////////////////////////////////////////

  const getIndicator = (day: Wellbeing[]) => {
    const dots = [];
    for (let i = 0; i < day.length; i++) {
      const wb = day[i];
      const happy = wb.wellbeing === WELLBEING_HAPPY;
      const neutral = !happy && wb.wellbeing === WELLBEING_NEUTRAL;
      const sad = !happy && !neutral && wb.wellbeing === WELLBEING_SAD;

      const className =
        (happy && css.happy) ||
        (neutral && css.neutral) ||
        (sad && css.sad) ||
        undefined;

      dots.push(<div key={"dot" + i} className={className} />);
    }
    return <div className={css.indicator}>{dots}</div>;
  };

  const getCalendar = () => {
    if (month < 0) return;
    const weekdays = getLanguageStringArray("general", "weekday_names_short");

    const columns = [];
    let rows = 0;

    for (let wd = 0; wd < 7; wd++) {
      let day = 1 + wd - firstWeekdayOfMonth;
      let row_count = 0;

      const col = [];
      col.push(
        <div key={"h" + wd} className={css.head}>
          {weekdays[wd]}
        </div>
      );

      while (true) {
        const d = day;
        const empty = !(d >= 1 && d <= daysOfMonth);
        const dayData = (!empty && data[month][d - 1]) || [];
        const className = empty
          ? css.empty
          : d === daySelected
          ? css.selected
          : css.cell;

        const cell = (
          <div
            key={"d" + d}
            className={className}
            onClick={
              (!empty && setDaySelected && (() => setDaySelected(d))) || undefined
            }
          >
            {!empty && <>{d}</>}
            {getIndicator(dayData)}
          </div>
        );
        col.push(cell);
        day += 7;
        row_count++;
        if (row_count > rows) rows = row_count;
        if (row_count < rows) continue;
        if (day > daysOfMonth) break;
      }
      columns.push(
        <div key={"col" + wd} className={css.column}>
          {col}
        </div>
      );
    }
    return columns;
  };

  return <div className={css.wellbeing_calendar}>{getCalendar()}</div>;
};

export default WellbeingCalendar;
