import React, { useState, useRef, useEffect } from "react";

import css from "./form.module.scss";
import { IconButton, TextField } from "@mui/material";
import { ValidatorFn } from "misc/validators";

export interface Props {
  type?: string;
  label?: string;
  autoHideLabel?: boolean;
  initialValue: string;
  updateValue: (value: string) => void;
  validator?: ValidatorFn;
  autocomplete?: string;
  endIcon?: any;
  iconAction?: () => void;
}

const TextInput: React.FC<Props> = (props) => {
  const {
    label,
    autoHideLabel = false,
    initialValue,
    updateValue,
    validator,
    autocomplete,
    type = "text",
    endIcon,
    iconAction,
  } = props;

  const [value, setValue] = useState<string>(initialValue);
  const [error, setError] = useState<string | null | undefined>(undefined);
  const [focused, setFocused] = useState<boolean>(false);

  const [updateTimeoutHandle, setUpdateTimeoutHandle] = useState<any>(null);

  const updateTimeoutHandleRef = useRef();
  updateTimeoutHandleRef.current = updateTimeoutHandle;

  /////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (value !== initialValue) setValue(initialValue);
    // eslint-disable-next-line
  }, [initialValue]);
  /////////////////////////////////////////////////////////////////////////

  const onChange = (value: string) => {
    if (updateTimeoutHandleRef.current) clearTimeout(updateTimeoutHandleRef.current);

    setValue(value);
    const error = validator && validator(value);
    setError(error);

    if (!error) {
      const timeout = setTimeout(() => updateValue(value), 500);
      setUpdateTimeoutHandle(timeout);
    }
  };

  /////////////////////////////////////////////////////////////////////////

  const showLabel = !autoHideLabel || (!focused && !value);

  return (
    <TextField
      className={css.textinput}
      fullWidth
      variant="outlined"
      margin="dense"
      //required={!!required}
      type={type}
      label={(showLabel && label) || undefined}
      value={value}
      error={!!error}
      helperText={error || ""}
      onChange={(event) => onChange(event.target.value)}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      autoComplete={autocomplete}
      InputProps={
        endIcon && {
          endAdornment: (
            <IconButton onClick={iconAction} tabIndex={-1} size="medium">
              {endIcon}
            </IconButton>
          ),
        }
      }
    />
  );
};

export default TextInput;
