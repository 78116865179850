import React, { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { getLanguageString as _ } from "misc/lang";

import TextInput from "components/form/TextInput";
import ListItem from "./ListItem";
import { Listable } from "model/data";

import css from "./listeditor.module.scss";
import FormButton from "components/form/FormButton";

export interface Props {
  items: Listable[];
  matchItem: (item: Listable, search: string) => boolean;
  selected?: Listable;
  onSelect: (item: any) => void;
  onAddNew?: () => void;
  getLabel?: (item: any) => string;
}

const SearchableList: React.FC<Props> = (props) => {
  const { items, selected, matchItem, onSelect, onAddNew, getLabel } = props;

  const [search, setSearch] = useState<string>("");
  const [filtered, setFiltered] = useState<Listable[]>(items);

  /////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (search === "" || !matchItem) {
      setFiltered(items);
      return;
    }

    const filtered: Listable[] = [];
    const words = search.toLowerCase().split(" ");

    loop: for (const item of items) {
      for (let word of words) {
        word = word.trim();
        if (!word) continue;
        const isMatch = matchItem(item, word);
        if (!isMatch) continue loop;
      }
      filtered.push(item);
    }

    setFiltered(filtered);
  }, [items, search, matchItem]);

  /////////////////////////////////////////////////////////////////////////

  return (
    <div className={css.list}>
      <div className={css.searchbox}>
        <TextInput
          label={_("general", "search")}
          autoHideLabel={true}
          initialValue={search}
          updateValue={setSearch}
          endIcon={<ClearIcon />}
          iconAction={() => setSearch("")}
        />
      </div>
      {onAddNew && (
        <div className={css.newButton}>
          <FormButton
            label={_("general", "btn_new")}
            onSubmit={(event) => onAddNew()}
          />
        </div>
      )}
      <div className={css.items}>
        {filtered &&
          filtered.map((item) => {
            return (
              <ListItem
                key={item.uuid}
                label={(getLabel && getLabel(item)) || item.name}
                onSelect={() => onSelect(item)}
                selected={selected?.uuid === item.uuid}
              />
            );
          })}
      </div>
    </div>
  );
};

export default SearchableList;
