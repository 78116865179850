import { Listable } from "model/data";

export const matchItem = (item: any, q: string): boolean => {
  if (!item) return false;
  if (!q) return true;

  const qlc = q.toLowerCase().trim();

  for (const key in item) {
    const value = item[key];
    if (!value) continue;
    if (String(value).toLowerCase().indexOf(qlc) >= 0) return true;
  }

  return false;
};

export const saveLocal = (list: Listable[], newItem: Listable, setList?: any) => {
  const _list = [...list];

  let updated = false;
  for (let i = 0; i < _list.length; i++) {
    if (_list[i].uuid !== newItem.uuid) continue;
    _list[i] = newItem;
    updated = true;
    break;
  }
  if (!updated) _list.push(newItem);

  setList && setList(_list);

  return _list;
};

export const deleteLocal = (list: Listable[], uuid: string, setList?: any) => {
  const _list = [...list];

  for (let i = 0; i < _list.length; i++) {
    if (_list[i].uuid !== uuid) continue;
    _list.splice(i, 1);
    break;
  }
  setList && setList(_list);
  return _list;
};

const defaults = {
  saveLocal,
  deleteLocal,
  matchItem,
};

export default defaults;
