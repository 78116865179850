import { lang as sv } from "assets/lang/sv/lang";

type LanguageStringGroup = { [code: string]: string | string[] };
type Language = { [group: string]: LanguageStringGroup };

const language: Language = sv;

const get = (group_name: string, code: string) => {
  const group = group_name ? language[group_name] : language;

  if (typeof group === "undefined")
    return "[" + (group_name ? group_name + "." : "") + code + "]";

  const lang = group[code];

  if (typeof lang === "undefined")
    return "[" + (group_name ? group_name + "." : "") + code + "]";

  return lang;
};

export const getLanguageString = (group_name: string, code: string) => {
  const lang_string = get(group_name, code);
  if (Array.isArray(lang_string))
    return "[" + (group_name ? group_name + "." : "") + code + "-IS-ARRAY]";

  return String(lang_string);
};

export const getLanguageStringArray = (group_name: string, code: string): string[] => {
  const lang_array = get(group_name, code);
  if (!Array.isArray(lang_array))
    return ["[" + (group_name ? group_name + "." : "") + code + "-NOT-ARRAY]"];

  return lang_array;
};
