export const general = {
  loading: "Var god vänta...",
  email: "e-postadress",
  password: "lösenord",
  search: "Sök",
  today: "Idag",

  btn_new: "Skapa ny",
  btn_save: "Spara",
  btn_delete: "Radera",
  btn_cancel: "Avbryt",
  btn_reset: "Återställ",
  btn_assign: "Lägg till",
  btn_logout: "Logga ut",
  confirm_delete: "Är du säker på att du vill radera:\n\n",
  btn_print: "Skriv ut",
  btn_download: "Ladda ned",
  btn_close: "Stäng",
  btn_back: "Tillbaka",
  btn_show_qr: "Visa QR-kod",

  btn_reset_password: "Spara lösenord",

  month_names: [
    "januari",
    "februari",
    "mars",
    "april",
    "maj",
    "juni",
    "juli",
    "augusti",
    "september",
    "oktober",
    "november",
    "december",
  ],

  weekday_names_short: ["mån", "tis", "ons", "tor", "fre", "lör", "sön"],
};
