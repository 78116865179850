import { ListableString } from "./data";

export interface Home extends ListableString {
  country: string;
  account_uuid: string;
  address_line1: string;
  address_line2: string;
  address_city: string;
  address_region: string;
  address_postal_code: string;
  signin_code: string;
}

export const HomeFields: string[] = [
  "name",
  "address_line1",
  "address_line2",
  "address_postal_code",
  "address_region",
];
