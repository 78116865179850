import React, { useState, useEffect } from "react";
import { getLanguageString as _ } from "misc/lang";

import { DefaultAndAuthProps } from "model/props";
import {
  apiGetUserList,
  apiUserCreate,
  apiUserDelete,
  apiUserUpdate,
  apiUserUpdateSignin,
} from "net/requests";
import SearchableList from "components/listeditor/SearchableList";
import { deleteLocal, matchItem, saveLocal } from "misc/list_helper";

import Editor from "components/listeditor/Editor";

import cssLayout from "../layout.module.scss";
import css from "./usersscreen.module.scss";
import cssQrPopup from "../qrpopup.module.scss";

import FormButton from "components/form/FormButton";
import { getQRImageDataURL, savePNGFile } from "misc/qr_utils";
import { User, UserFields } from "model/user";

const UsersScreen: React.FC<DefaultAndAuthProps> = (props) => {
  const { admin, account, home, loader } = props;

  const [users, setUsers] = useState<User[]>([]);
  const [selected, setSelected] = useState<User>();
  const [editorItem, updateEditorItem] = useState<User>();
  // const [error, setError] = useState<string>();

  const [qrImgData, setQrImgData] = useState<string>();
  const [showQr, setShowQr] = useState<boolean>(false);

  /////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (admin?.access_token && account) {
      setUsers([]);
      actionLoadUsers();
    }
    // eslint-disable-next-line
  }, [home]);

  useEffect(() => {
    if (!selected && users && users.length) setSelected(users[0]);
    // eslint-disable-next-line
  }, [users]);

  useEffect(() => {
    if (selected) {
      updateEditorItem({ ...selected });
      const imageData = getQRImageDataURL(selected?.signin_code || "qr-kod", true, 10);
      setQrImgData(imageData);
    } else {
      updateEditorItem(undefined);
    }
    // eslint-disable-next-line
  }, [selected]);
  /////////////////////////////////////////////////////////////////////////

  const actionLoadUsers = () => {
    if (!home) return;

    apiGetUserList(admin, home.uuid)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  /////////////////////////////////////////////////////////////////////////

  const actionCancel = () => {
    if (selected) updateEditorItem({ ...selected });
  };

  const actionNew = () => {
    if (!home) return;

    loader.setLoading();
    apiUserCreate(admin, home.uuid, "")
      .then((response) => {
        loader.reset();
        setUsers([...users, response.data]);
        setSelected(response.data);
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  const actionSave = () => {
    if (!editorItem || !selected) return;

    loader.setLoading();
    apiUserUpdate(admin, selected.uuid, editorItem)
      .then((response) => {
        loader.reset();
        saveLocal(users, editorItem, setUsers);
        setSelected(editorItem);
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  const actionDelete = () => {
    if (!selected) return;

    loader.setLoading();
    apiUserDelete(admin, selected.uuid)
      .then((response) => {
        loader.reset();
        deleteLocal(users, selected.uuid, setUsers);
        setSelected(undefined);
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  /////////////////////////////////////////////////////////////////////////

  const actionUpdateSignin = () => {
    if (!selected) return;

    loader.setLoading();
    apiUserUpdateSignin(admin, selected?.uuid)
      .then((response) => {
        loader.reset();
        saveLocal(users, response.data, setUsers);
        setSelected(response.data);
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  /////////////////////////////////////////////////////////////////////////

  const qrPopup = (
    <div className={cssQrPopup.popupWrapper}>
      <div className={cssQrPopup.popup}>
        <div className={cssQrPopup.heading}>{selected?.identifier || ""}</div>
        <img className={cssQrPopup.qrCode} src={qrImgData} alt="QR"></img>
        <div className={cssQrPopup.buttons}>
          <FormButton
            label={_("account", "btn_regenerate_qr")}
            onSubmit={(event) => actionUpdateSignin()}
            variant={"outlined"}
          />
          <FormButton
            label={_("general", "btn_print")}
            onSubmit={(event) => window.print()}
            variant={"outlined"}
          />
          <FormButton
            label={_("general", "btn_download")}
            onSubmit={() =>
              selected &&
              savePNGFile(selected.signin_code, selected.identifier || "qr", true)
            }
            variant={"outlined"}
          />
          <FormButton
            label={_("general", "btn_close")}
            onSubmit={(event) => setShowQr(false)}
            variant={"outlined"}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className={cssLayout.screen}>
        <div className={cssLayout.pageHeading}>
          {(home?.name || "") + " - " + _("user", "page_heading")}
        </div>
        <div className={css.homes}>
          <div className={cssLayout.listeditor}>
            <div className={cssLayout.list}>
              <SearchableList
                items={users}
                matchItem={matchItem}
                onSelect={setSelected}
                selected={selected}
                onAddNew={actionNew}
                getLabel={(item: User) => item.identifier}
              ></SearchableList>
            </div>
            <div className={cssLayout.editor}>
              {selected && (
                <>
                  {editorItem && (
                    <Editor
                      item={editorItem}
                      updateItem={updateEditorItem}
                      fields={UserFields}
                      getLabel={(item: User) => item.identifier}
                      string_group={"user"}
                      onDelete={actionDelete}
                      onSave={actionSave}
                      onCancel={actionCancel}
                      secondaryAction={() => setShowQr(true)}
                      secondaryActionLabel={_("general", "btn_show_qr")}
                    />
                  )}
                  {!selected && _("user", "select_account_from_list")}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {selected && showQr && qrPopup}
    </>
  );
};

export default UsersScreen;
