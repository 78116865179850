export const auth = {
  btn_login: "Logga in",
  btn_forgot_password: "Glömt lösenord?",
  btn_trigger_password_reset: "Återställ lösenord",

  pw_error_digit: "Use at least one digit (0-9)",
  pw_error_lowercase: "Use at least one lowercase letter (a-z)",
  pw_error_uppercase: "Use at least one uppercase letter (A-Z)",
  pw_error_whitespace: "Do not use whitespace characters",
  pw_error_length: "Use minimum 8 characters",

  forgot_password_head: "Återställ lösenord",
  forgot_password_info:
    "Fyll i din e-postadress och tryck på knappen Återställ Lösenord. Du får ett e-postmeddelande med en återställningslänk.",

  login_head: "Logga in",
  login_info: "Fyll i din e-postadress och lösenord.",

  login_error: "Något gick fel vid inloggningen, försök igen senare.",

  reset_password_head: "Ange nytt lösenord",
  reset_password_info: "Ange din e-postadress och ett nytt lösenord.",

  trigger_pw_reset_error:
    "Något gick fel vid lösenordsåterställning, försök igen senare.",

  trigger_pw_reset_head: "Återställ lösenord",
  trigger_pw_reset_response:
    "Om ditt konto hittades har ett email nu skickats ut med en länk för lösenords-återställning.",

  pw_reset_head: "Spara nytt lösenord",
  pw_reset_response: "Ditt nya lösenord är sparat.",
};
