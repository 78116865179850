export const formatTimestampDate = (
  date: Date,
  weekday: boolean = false,
  offsetDays: number = 0
) => {
  var weekdays = ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"];

  var months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "maj",
    "jun",
    "jul",
    "aug",
    "sep",
    "okt",
    "nov",
    "dec",
  ];

  if (offsetDays !== 0) date.setTime(date.getTime() + offsetDays * 24 * 3600 * 1000);

  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  var time = day + " " + month + " " + year;
  if (weekday) {
    const dow = date.getDay(); //0 = Sunday
    time = weekdays[dow] + " " + time;
  }

  return time;
};

export const formatTimestampUnix = (timestamp: number) => {
  if (!timestamp) return "";
  return formatTimestampDate(new Date(timestamp * 1000));
};

export const getTimeString = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const string_hours = hours < 10 ? "0" + hours : "" + hours;
  const string_minutes = minutes < 10 ? "0" + minutes : "" + minutes;

  return string_hours + ":" + string_minutes;
};

export const getWeekday = (date: Date): number => {
  // return 0 = monday .. 6 = sunday
  const weekday: number = date.getDay(); // 0 = sunday .. 6 = saturday
  return (weekday + 6) % 7;
};

export const getJSDate = (utc: number, offsetDays: number = 0): Date => {
  if (!utc) return new Date(0) as any;
  if (utc > 99991231) utc = Math.floor(utc / 100);

  const year: number = Math.floor(utc / 10000);
  const month: number = Math.floor((utc % 10000) / 100);
  const day: number = utc % 100;

  const date: Date = new Date(year, month - 1, day, 12);

  if (offsetDays !== 0) date.setTime(date.getTime() + offsetDays * 24 * 3600 * 1000);

  date.setHours(0);
  return date;
};

export const unixtime2JSDate = (unixtime: number): Date => {
  if (!unixtime) return new Date(0) as any;
  while (unixtime > 9999999999) {
    unixtime = Math.floor(unixtime / 10);
  }
  const date: Date = new Date(unixtime * 1000);
  return date;
};

export const utcAddDays = (utc: number, add: number = 0): number => {
  if (!utc) return 0;
  if (!add) return utc;

  const day: number = utc % 100;
  const newday = day + add;
  if (add > 0) {
    if (newday <= 28) return Math.floor(utc / 100) * 100 + newday;
  } else {
    if (newday >= 1) return Math.floor(utc / 100) * 100 + newday;
  }
  const dateAdded = getJSDate(utc, add);
  return getUtcDate(dateAdded);
};

export const getUtcDate = (date: Date = new Date(), offsetDays: number = 0): number => {
  const d = new Date(date.getTime() + offsetDays * 24 * 3600 * 1000);
  const year: number = d.getFullYear();
  const month: number = d.getMonth() + 1;
  const day: number = d.getDate();
  return year * 10000 + month * 100 + day;
};

export const daysBetweenJSDates = (date1: Date, date2: Date) => {
  const diffDays: number = Math.round(
    (date2.getTime() - date1.getTime()) / (24 * 3600 * 1000)
  );
  return diffDays;
};
export const getDateOffsetJSDates = (date1: Date, date2: Date) => {
  const epochDay1 = Math.floor(
    (date1.getTime() - date1.getTimezoneOffset() * 60000) / (24 * 3600 * 1000)
  );
  const epochDay2 = Math.floor(
    (date2.getTime() - date2.getTimezoneOffset() * 60000) / (24 * 3600 * 1000)
  );
  return epochDay2 - epochDay1;
};

export const daysBetweenUtcDates = (utc1: number = 0, utc2: number = 0) => {
  if (utc1 === 0 || utc2 === 0) return 0;
  if (Math.abs(utc2 - utc1) <= 30) return utc2 - utc1; //both dates in same month

  const date1: Date = getJSDate(utc1);
  const date2: Date = getJSDate(utc2);
  return daysBetweenJSDates(date1, date2);
};

export const getDateList = (utcStart: number, utcEndInclusive: number): Date[] => {
  const count: number = daysBetweenUtcDates(utcStart, utcEndInclusive) + 1;
  const date: Date = getJSDate(utcStart);
  const list: Date[] = [new Date(date.valueOf())];

  for (var i: number = 1; i < count; i++) {
    date.setDate(date.getDate() + 1);
    list.push(new Date(date.valueOf()));
  }
  return list;
};

const defaults = {
  formatTimestampDate,
  formatTimestampUnix,
  getTimeString,
  getJSDate,
  getUtcDate,
  daysBetweenJSDates,
  daysBetweenUtcDates,
  getDateOffsetJSDates,
  getDateList,
  utcAddDays,
  getWeekday,
};

export default defaults;
