import { useState } from "react";
import internal from "stream";

const LOADER_SHOW_DELAY = 150;

export interface LoaderState {
  show?: boolean;
  loading?: boolean;
  loadingCount?: number;
  success?: boolean;
  heading?: string;
  text?: string;
  action?: () => void;
  timeout?: any;
  loader?: LoaderUtil;
}

export class LoaderUtil {
  setter: (state: LoaderState) => void;

  show: boolean;
  loading: boolean;
  loadingCount: number;
  success: boolean;
  heading: string;
  text: string;
  action: any;
  timeout: any;

  constructor(state: LoaderState, setter: (state: LoaderState) => void) {
    this.setter = setter;

    this.show = state.show || false;
    this.loading = state.loading || false;
    this.loadingCount = state.loadingCount || 0;
    this.success = state.success || false;
    this.heading = state.heading || "";
    this.text = state.text || "";
    this.action = state.action || null;
    this.timeout = state.timeout || null;
  }

  set() {
    const value = {
      show: this.show,
      loading: this.loading,
      loadingCount: this.loadingCount,
      success: this.success,
      heading: this.heading,
      text: this.text,
      action: this.action,
      timeout: this.timeout,
      loader: this,
    };
    this.setter(value);
  }

  setLoading(count: number = 1): boolean {
    if ((this.show && this.loading) || this.timeout) return false;

    this.timeout = window.setTimeout(() => {
      if (this.timeout) this.setLoadingAfterDelay(this, count);
    }, LOADER_SHOW_DELAY);
    this.set();
    return true;
  }

  setLoadingAfterDelay(loader_this: LoaderUtil, count: number = 1) {
    this._clearTimeout();
    loader_this.show = true;
    loader_this.loading = true;
    loader_this.loadingCount += count;
    loader_this.set();
  }

  _clearTimeout(setState: boolean = true) {
    if (this.timeout) window.clearTimeout(this.timeout);
    this.timeout = null;
    if (setState) this.set();
  }

  setResult(success: boolean, heading: string, text: string, action?: () => void) {
    this._clearTimeout(false);
    this.show = true;
    this.loading = false;
    this.loadingCount = 0;
    this.success = success;
    this.heading = heading;
    this.text = text;
    this.action = action;
    this.set();
  }

  resetOnZero(count: number = 1) {
    if (--this.loadingCount <= 0) this.reset();
  }

  reset() {
    this._clearTimeout(false);
    this.show = false;
    this.loading = false;
    this.loadingCount = 0;
    this.success = false;
    this.heading = "";
    this.text = "";
    this.action = null;
    this.set();
  }
}

export const useLoader = (): [LoaderState, LoaderUtil] => {
  const [loaderState, setLoaderState] = useState<LoaderState>({});
  const loader = new LoaderUtil(loaderState, setLoaderState);
  return [loaderState, loader];
};

const defaults = {
  LoaderUtil,
  useLoader,
};

export default defaults;
