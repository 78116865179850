import { general } from "./general";
import { account } from "./account";
import { auth } from "./auth";
import { user } from "./user";
import { staff } from "./staff";
import { access } from "./access";
import { validator } from "./validator";
import { footer } from "./footer";
import { topmenu } from "./topmenu";
import { homes } from "./homes";
import { stats } from "./stats";
import { calendar } from "./calendar";
import { wellbeing } from "./wellbeing";

export const lang = {
  general,
  account,
  auth,
  user,
  staff,
  access,
  validator,
  footer,
  topmenu,
  homes,
  stats,
  calendar,
  wellbeing,
};
