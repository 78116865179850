import { getLanguageString as _ } from "./lang";

const PW_REGEX_DIGIT = "(?=.*[0-9])";
const PW_REGEX_LOWERCASE = "(?=.*[a-z])";
const PW_REGEX_UPPERCASE = "(?=.*[A-Z])";
const PW_REGEX_NO_WHITESPACE = "^(?=\\S+$)";
//const PW_REGEX_SPECIAL_CHAR = "(?=.*[@#$%^&+=])";
const PW_REGEX_LENGTH = ".{8,}";

const PW_REGEX = [
  PW_REGEX_NO_WHITESPACE,
  PW_REGEX_LOWERCASE,
  PW_REGEX_UPPERCASE,
  PW_REGEX_DIGIT,
  PW_REGEX_LENGTH,
];

export type ValidatorFn = (str: string) => string | null;

export const validatePassword: ValidatorFn = (str: string): string | null => {
  if (str.length === 0) return null;
  if (str.length < 8) return _("validator", "pw_error");
  if (str.length >= 16) return null; // accept all passwords of length 16

  for (const regex of PW_REGEX) {
    const result = str.match(regex);
    if (!result) return _("validator", "pw_error");
  }
  return null;
};

export const validateEmailAddress: ValidatorFn = (
  email: string
): string | null => {
  if (!email || email.length === 0) return null;
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValidEmail = regex.test(String(email).toLowerCase());
  return isValidEmail ? null : _("validator", "email_error_invalid");
};

const defaults = {
  validatePassword,
  validateEmailAddress,
};

export default defaults;
