export const BaseURL: string = "https://addyou.se/";
export const BaseURLWww: string = "https://www.addyou.se/";

/////////////////////////////////////////////////////////////////////
export const COLOR_RED = "#ff0000";
export const COLOR_YELLOW = "#ffce00";
export const COLOR_GREEN = "#3fa149";
export const COLOR_GRAY = "#494f56";

export const PERCENT_RED = 30;
export const PERCENT_YELLOW = 50;

export const COLOR_PALETTE = [
  "#5296f3",
  "#db6bb4",
  "#5ee3a1",
  "#ffbc5d",
  "#ff0180",

  "#110097",
  "#dd2230",
  "#f96c05",
  "#49b93a",
  "#008f96",

  "#3c0196",
  "#662d91",
  "#df41d1",
  "#db4e23",
  "#f79b0b",
  "#f8e94d",
  "#87c130",
  "#87c130",
];
/////////////////////////////////////////////////////////////////////

export type ConfigMap = { [key: string]: string };

/////////////////////////////////////////////////////////////////////

export const Paths: ConfigMap = {
  LANDING_PAGE: "/",
  ABOUT: "/about",
  SIGNIN: "/login",
  RESET_PASSWORD: "/reset-password",
  ACCOUNT: "/account",
  HOMES: "/homes",
  STAFF: "/staff",
  USERS: "/users",
  STATISTICS: "/statistics",
  CALENDAR_AGENDA: "/calendar",
};

export const VALID_PATHS = Object.keys(Paths).map((key) => Paths[key]);

/////////////////////////////////////////////////////////////////////

export const TopMenuAll: ConfigMap = {
  home: Paths.LANDING_PAGE,
  about: Paths.ABOUT,
};
export const TopMenuSuperAdmin: ConfigMap = {
  account: Paths.ACCOUNT,
  homes: Paths.HOMES,
  staff: Paths.STAFF,
  users: Paths.USERS,
  calendar: Paths.CALENDAR_AGENDA,
  statistics: Paths.STATISTICS,
};

export const TopMenuAccountAdmin: ConfigMap = {
  account: Paths.ACCOUNT,
  homes: Paths.HOMES,
  staff: Paths.STAFF,
  users: Paths.USERS,
  calendar: Paths.CALENDAR_AGENDA,
  statistics: Paths.STATISTICS,
};

export const TopMenuOperationsManager: ConfigMap = {
  homes: Paths.HOMES,
  staff: Paths.STAFF,
  users: Paths.USERS,
  calendar: Paths.CALENDAR_AGENDA,
  statistics: Paths.STATISTICS,
};

export const TopMenuHomeManager: ConfigMap = {
  homes: Paths.HOMES,
  staff: Paths.STAFF,
  users: Paths.USERS,
  calendar: Paths.CALENDAR_AGENDA,
  statistics: Paths.STATISTICS,
};

/////////////////////////////////////////////////////////////////////

export const AuthResponse: ConfigMap = {
  USER_ALREADY_EXIST: "user-already-exists",
  USER_LOOKUP_ERROR: "user-lookup-error",
  EMAIL_PASSWORD_MISMATCH: "email-password-mismatch",
  EMAIL_NOT_VERIFIED: "email-not-verified",
  NOT_FOUND: "not-found",
  RATE_LIMIT_BLOCK: "rate-limit-block",
};

export const PASSWORD_RULES =
  "minlength: 8; maxlength: 40; required: lower; required: upper; required: digit;";

/////////////////////////////////////////////////////////////////////
export const LS_ACCESS_TOKEN = "accesstoken";

/////////////////////////////////////////////////////////////////////
export const ACTIVITY_PERFORMED = "performed";
export const ACTIVITY_UNABLE = "unable";
export const ACTIVITY_NOT_PERFORMED = "not_performed";

/////////////////////////////////////////////////////////////////////
export const WELLBEING_HAPPY = "happy";
export const WELLBEING_NEUTRAL = "neutral";
export const WELLBEING_SAD = "sad";
