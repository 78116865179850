import React from "react";
import { classNames } from "misc/helpers";

import css from "./listeditor.module.scss";

export interface Props {
  label?: string;
  onSelect: () => void;
  selected: boolean;
}

const ListItem: React.FC<Props> = (props) => {
  const { label, onSelect, selected } = props;

  /////////////////////////////////////////////////////////////////////////

  return (
    <div
      className={classNames(css.listitem, selected && css.listitem_selected)}
      onClick={onSelect}
    >
      <div className={css.itemlabel}>{label || "-n/a-"}</div>
    </div>
  );
};

export default ListItem;
