export const findArrayObjectbyAttr = (array: any, attr: string, value: string): any => {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return array[i];
    }
  }
  return null;
};

export const formatTimestamp = (timestamp?: number) => {
  if (!timestamp) return "";
  var a = new Date(timestamp * 1000);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  // var hour = a.getHours();
  // var min = a.getMinutes();
  // var sec = a.getSeconds();
  var time = date + " " + month + " " + year;
  return time;
};

export const hashCode = (str: string): number => {
  return Array.from(str).reduce(
    (aggr: number, char: string) => (aggr * 31 + char.charCodeAt(0)) & 0x7fffffff,
    0
  );
};

export const saveBlobToFile = (blob: Blob, filename: string) => {
  const a = document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  a.click();
};

export const arabicToRoman = (num: number): string => {
  const roman: any = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  var str = "";

  for (var i of Object.keys(roman)) {
    var q = Math.floor(num / roman[i]);
    num -= q * roman[i];
    str += i.repeat(q);
  }

  return str;
};

export const removeUnprintableChars = (text: string): string => {
  if (!text) return "";

  const newText: number[] = [];
  let strip = false;

  for (let i = 0; i < text.length; i++) {
    const charCode = text.charCodeAt(i);
    if (charCode >= 0x2000 && charCode <= 0x206f) {
      strip = true;
      continue;
    }
    newText.push(charCode);
  }

  if (!strip) return text;
  return String.fromCharCode(...newText);
};
export const formatString = function (...args: any[]) {
  if (args.length <= 0) return "";
  const str = "" + args[0];
  return str.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
};

const defaults = {
  findArrayObjectbyAttr,
  formatTimestamp,
  saveBlobToFile,
  hashCode,
  arabicToRoman,
  removeUnprintableChars,
  formatString,
};

export default defaults;
