import React from "react";

import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import css from "./adminlist.module.scss";
import { Admin } from "model/admin";

export interface Props {
  admin: Admin;
  onDelete: () => void;
}

const AdminItem: React.FC<Props> = (props) => {
  const { admin, onDelete } = props;

  /////////////////////////////////////////////////////////////////////////

  return (
    <div className={css.admin}>
      <div className={css.label}>{admin.email_address}</div>
      <div className={css.iconbutton}>
        <IconButton onClick={onDelete} tabIndex={-1} size="medium">
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default AdminItem;
