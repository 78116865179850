import React, { useState, useRef, useEffect } from "react";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { getLanguageString as _, getLanguageStringArray } from "misc/lang";
import { getLanguageStringArray as _ar } from "misc/lang";
import { classNames } from "misc/helpers";

import { DefaultProps } from "model/props";
import { apiGetCalendarHomeAgenda } from "net/requests";

import cssLayout from "../layout.module.scss";
import css from "./calendarscreen.module.scss";

import { CalendarAgenda, CalendarPersonAgenda } from "model/calendar";
import { processCalendarAgenda } from "misc/calendar_utils";
import { getObjectiveColor } from "misc/stats_util";
import FormButton from "components/form/FormButton";
import { formatTimestampDate } from "misc/date_utils";
import { IconButton } from "@mui/material";

const LOAD_DAYS = 7;

const CalendarScreen: React.FC<DefaultProps> = (props) => {
  const { admin, home, account, loader } = props;

  const [dayOffset, setDayOffset] = useState<number>(0);
  const [loadedOffset, setLoadedOffset] = useState<number>(9999);
  const [personAgenda, setPersonAgenda] = useState<CalendarPersonAgenda[]>();

  const dayIndex = dayOffset - loadedOffset;
  const loading = !personAgenda || dayIndex < 0 || dayIndex >= LOAD_DAYS;

  const ref = useRef();

  /////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (
      loadedOffset !== 9999 &&
      dayOffset < loadedOffset + LOAD_DAYS &&
      dayOffset >= loadedOffset
    )
      return;

    setPersonAgenda(undefined);
    setLoadedOffset(9999);
    actionLoadCalendarAgenda(dayOffset);
    // eslint-disable-next-line
  }, [home, dayOffset]);

  /////////////////////////////////////////////////////////////////////////

  const actionLoadCalendarAgenda = (todayOffset: number) => {
    if (!home) return;
    loader.setLoading();

    if (todayOffset < 0) todayOffset -= 3; // if someone starts looking at the history, load some more history days.

    apiGetCalendarHomeAgenda(admin, home.uuid, todayOffset, LOAD_DAYS)
      .then((response) => {
        loader.resetOnZero();
        const loadedData: CalendarAgenda[] = response.data; //FAKE_DATA;
        setLoadedOffset(todayOffset);
        const date = new Date();
        date.setTime(date.getTime() + todayOffset * 24 * 3600 * 1000);
        const processedData: CalendarPersonAgenda[] = processCalendarAgenda(
          loadedData,
          date,
          LOAD_DAYS
        );
        setPersonAgenda(processedData);
      })
      .catch((error) => {
        loader.resetOnZero();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  /////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////

  const getCellStyling = (person: CalendarPersonAgenda) => {
    const maxConcurrentCount = person.max_activites_per_hour[dayIndex];
    return {
      flexGrow: maxConcurrentCount,
      maxWidth: 40 + maxConcurrentCount * 200 + "px",
    };
  };

  const getActivity = (activity: CalendarAgenda) => {
    const title = activity.activity_time_string + "\n" + activity.activity;
    return (
      <div
        key={
          "act:" +
          activity.user +
          ":" +
          activity.activity_id +
          ":" +
          activity.activity_timestamp
        }
        className={css.activity}
        style={{ backgroundColor: getObjectiveColor(activity.objective_color) }}
        title={title}
      >
        <div className={css.line}>
          <div className={css.startTime}>{activity.activity_time_string}</div>
          <div className={css.objective}>{activity.objective}</div>
        </div>
        <div className={css.name}>{activity.activity}</div>
      </div>
    );
  };

  const getCalendarRows = () => {
    if (loading) return <></>;
    const hours = getLanguageStringArray("calendar", "hours");
    const rows = [];

    for (let hour = 0; hour < 24; hour++) {
      const columns = [];

      for (let pi = 0; pi < personAgenda.length; pi++) {
        const person = personAgenda[pi];
        const hourActivities = person.days[dayIndex][hour];

        const activites = [];
        for (let ai = 0; ai < hourActivities.length; ai++) {
          const activity = getActivity(hourActivities[ai]);
          activites.push(activity);
        }

        columns.push(
          <div
            key={"cell:" + person.user + ":" + hour}
            className={css.cell}
            style={getCellStyling(person)}
          >
            {activites}
          </div>
        );
      }
      rows.push(
        <div key={"row:" + hour} className={css.row}>
          <div className={css.time}>{hours[hour]}</div>
          {columns}
        </div>
      );
    }
    return rows;
  };

  return (
    <div className={cssLayout.screen}>
      <div className={css.stats}>
        {/* HEADER */}
        <div className={css.calendar}>
          <div className={css.control}>
            <div className={css.title}>
              {home?.name} - {_("calendar", "main_title")}
            </div>
            <FormButton
              onSubmit={(event) => setDayOffset(0)}
              variant="outlined"
              color="inherit"
              label={_("general", "today")}
              size="small"
              inline={true}
            />
            <div className={css.selectDate}>
              <IconButton
                onClick={(event) => setDayOffset(dayOffset - 1)}
                color="inherit"
                size="small"
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <div className={css.dateShowing}>
                {formatTimestampDate(new Date(), true, dayOffset)}
              </div>
              <IconButton
                onClick={(event) => setDayOffset(dayOffset + 1)}
                size="small"
                color="inherit"
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
          </div>
          {!loading && (
            <div className={css.table}>
              <div className={css.row}>
                <div className={css.time}></div>
                <>
                  {personAgenda.map((person) => {
                    return (
                      <div
                        key={"header:" + person.user}
                        className={css.header}
                        style={getCellStyling(person)}
                      >
                        {person.user_identifier}
                      </div>
                    );
                  })}
                </>
              </div>
              <>{getCalendarRows()}</>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalendarScreen;
