import React from "react";

import { getLanguageString as _ } from "misc/lang";
import { classNames } from "misc/helpers";

import css from "./box.module.scss";

import { getStatus } from "misc/stats_util";
import { MonthlyStatsAndSummary } from "model/stats";

export interface Props {
  id: string;
  name: string;
  item: MonthlyStatsAndSummary;
  period: number;
  fnClick?: (event: any) => void;
  style?: Object;
}

const BoxPercent: React.FC<Props> = (props) => {
  const { id, name, item, period, fnClick, style } = props;

  const periodSummary = period < 0 ? item.summary : item.months[period];
  const percent = periodSummary?.percent_performed || 0;
  const cssColor = getStatus(percent, css.red, css.yellow, css.green);

  return (
    <div
      key={"boxp_" + id}
      className={classNames(css.box, fnClick && css.clickable)}
      onClick={(event) => fnClick && fnClick(event)}
      style={style}
    >
      <div className={css.content}>
        <div className={css.top}>
          <div className={css.name}>{name}</div>
        </div>
        <div className={css.grow}></div>
        <div className={css.bottom}>
          <div className={classNames(css.dot, cssColor)}>{percent}%</div>
        </div>
      </div>
    </div>
  );
};

export default BoxPercent;
