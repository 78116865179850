export const homes = {
  page_heading: "Boenden",

  editor_label_name: "Namn",
  editor_label_address_line1: "Adress",
  editor_label_address_line2: "Adress, rad 2",
  editor_label_address_postal_code: "Postnummer",
  editor_label_address_region: "Ort",

  number_admins_assigned: "person(er)",
  operation_manager_heading: "Processägare - Behörighet 2",
  operation_manager_add_placeholder: "Lägg till ny, ange e-postadress",
  confirm_remove_manager_operation: "Vill du verkligen ta bort processägaren:\n\n",

  home_manager_heading: "Processägare - Behörighet 3",
  home_manager_add_placeholder: "Lägg till ny, ange e-postadress",
  confirm_remove_manager_home: "Vill du verkligen ta bort processägaren:\n\n",
};
