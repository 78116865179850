import React, { useState } from "react";
import { getLanguageString as _ } from "misc/lang";

import css from "../../components/form/form.module.scss";
import TextInput from "../../components/form/TextInput";
import { validateEmailAddress } from "misc/validators";
import { DefaultAndAuthProps } from "model/props";
import FormButton from "../../components/form/FormButton";
import { apiResetPassword } from "net/requests";
import { Paths } from "appConfig";

const PasswordResetScreen: React.FC<DefaultAndAuthProps> = (props) => {
  const { passwordResetToken, loader, navigate } = props;

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const canResetPassword = !!email && !!password;

  /////////////////////////////////////////////////////////////////////////

  const actionResetPassword = (event: any) => {
    event.preventDefault();

    if (!canResetPassword) return;

    loader.setLoading();
    apiResetPassword(email, String(passwordResetToken), password)
      .then((response) => {
        loader.setResult(
          true,
          _("auth", "pw_reset_head"),
          _("auth", "pw_reset_response")
        );
        navigate(Paths.SIGNIN);
      })
      .catch((error) => {
        loader.reset();
        setError(_("auth", "pw_reset_error"));
      });
  };
  /////////////////////////////////////////////////////////////////////////

  return (
    <div className={css.form}>
      <form onSubmit={actionResetPassword}>
        <div className={css.heading}>{_("auth", "reset_password_head")}</div>
        <div className={css.info}>{_("auth", "reset_password_info")}</div>

        <TextInput
          key={"login_email"}
          autocomplete="email"
          label={_("general", "email")}
          initialValue={email}
          updateValue={setEmail}
          validator={validateEmailAddress}
        />
        <TextInput
          key={"login_password"}
          autocomplete="new-password"
          type="password"
          label={_("general", "password")}
          initialValue={password}
          updateValue={setPassword}
        />

        <FormButton
          label={_("general", "btn_reset_password")}
          onSubmit={actionResetPassword}
          disabled={!canResetPassword}
        />
      </form>
      {error && <div className={css.error}>{error}</div>}
    </div>
  );
};

export default PasswordResetScreen;
