export const footer = {
  terms_of_service: "Terms of Service",
  privacy_policy: "Privacy Policy",
  legal_notice: "Legal Notice",
  cookie_consent: "Cookie Consent",
  copyright: "© 2011-%s OLTO AB",
  contact:
    'Contact us on <a href="mailto:hello@addyou.se">hello@addyou.se</a> or <a href="mailto:support@addyou.se">support@addyou.se</a>',
  info:
    'Originally created by <a href="https://www.mobileinteraction.se" target="_blank" rel="noopener">Mobile Interaction - Apputveckling Stockholm</a>',
};
