import React from "react";

import Link from "@mui/material/Link";

import { DefaultAndAuthProps } from "model/props";

import { getLanguageString as _ } from "misc/lang";
import Logo from "assets/images/addyou_white.png";
import {
  ConfigMap,
  TopMenuAccountAdmin,
  TopMenuHomeManager,
  TopMenuOperationsManager,
  TopMenuSuperAdmin,
} from "appConfig";
import css from "./topmenu.module.scss";
import { signOut } from "misc/auth_utils";
import FormButton from "components/form/FormButton";
import { classNames } from "misc/helpers";

const TopMenu: React.FC<DefaultAndAuthProps> = (props) => {
  const { admin, account, home, path, navigate } = props;

  let menuItems: ConfigMap = {};

  let access_level_text = "";

  if (admin) {
    if (admin.is_super_admin) {
      menuItems = { ...menuItems, ...TopMenuSuperAdmin };
      access_level_text = _("topmenu", "access_level_super_admin");
    } else if (admin.is_account_admin) {
      menuItems = { ...menuItems, ...TopMenuAccountAdmin };
      access_level_text = _("topmenu", "access_level_account_admin");
    } else if (admin.is_operations_manager) {
      menuItems = { ...menuItems, ...TopMenuOperationsManager };
      access_level_text = _("topmenu", "access_level_operations_manager");
    } else if (admin.is_home_manager) {
      menuItems = { ...menuItems, ...TopMenuHomeManager };
      access_level_text = _("topmenu", "access_level_home_manager");
    }
  }

  return (
    <div className={css.topMenu}>
      <div
        className={css.logoWrapper}
        onClick={() => window.open("https://www.addyou.se", "_blank", "noopener")}
      >
        <img className={css.logo} src={Logo} alt="Add You" />
      </div>

      <div className={css.subMenu}>
        {Object.keys(menuItems).map((item) => {
          const isAccount = item === "account";
          const isStats = item === "statistics";

          return (
            <div
              className={classNames(css.item, path === menuItems[item] && css.selected)}
              key={item}
            >
              <Link
                href={menuItems[item]}
                onClick={(event: any) => {
                  event.preventDefault();
                  navigate(menuItems[item]);
                  return false;
                }}
              >
                {_("topmenu", item)}
              </Link>
              {isAccount && <div className={css.subtext}>{account?.name}</div>}
              {!isAccount && !isStats && (
                <div className={css.subtext}>{home?.name || ""}</div>
              )}
            </div>
          );
        })}
      </div>

      {admin && admin.access_token && (
        <div className={css.subMenu}>
          <div
            className={css.accessLevel}
            title={_("topmenu", "logged_in_with") + access_level_text}
          >
            {access_level_text}
          </div>
          <FormButton
            onSubmit={() => signOut(props)}
            variant="outlined"
            color="inherit"
            label={_("general", "btn_logout")}
            size="small"
          />
        </div>
      )}
    </div>
  );
};

export default TopMenu;
