import React from "react";

import { getLanguageString as _ } from "misc/lang";
import { classNames } from "misc/helpers";

import css from "./box.module.scss";

import { getStatus } from "misc/stats_util";
import { MonthlyStatsAndSummary, StaffSignOffStats } from "model/stats";
import { formatString } from "misc/utils";

export interface Props {
  staff: StaffSignOffStats;
  period: number;
}

const BoxSignOff: React.FC<Props> = (props) => {
  const { staff, period } = props;

  let periodSummary = staff.summary;
  let active_days = staff.active_days_total || 0;
  if (period >= 0) {
    periodSummary = staff.months[period];
    active_days = staff.active_days[period];
  }

  const total = periodSummary.count_total || 0;
  const performed = periodSummary.count_performed || 0;
  const unable = periodSummary.count_unable_to_perform || 0;
  const not_performed = total - performed - unable;

  const per_day = (total && Math.round((total * 10) / active_days) / 10) || 0;
  const per_day_info = formatString(
    _("stats", "per_day_template"),
    total,
    active_days,
    per_day
  );

  const percent_performed = (total && Math.floor((performed * 1000) / total) / 10) || 0;
  const percent_unable = (total && Math.floor((unable * 1000) / total) / 10) || 0;
  const percent_not_performed =
    (total && Math.floor((not_performed * 1000) / total) / 10) || 0;

  return (
    <div key={staff.staff_uuid} className={css.box}>
      <div className={classNames(css.content, css.signoff)}>
        <div className={css.top} title={per_day_info}>
          <div className={css.name}>{staff.staff_identifier}</div>
          <div className={css.activites_per_day}>{per_day + _("stats", "per_day")}</div>
          <div className={css.activites}>
            {active_days +
              " " +
              (active_days === 1 ? _("stats", "day") : _("stats", "days"))}
          </div>
        </div>

        <div className={css.grow}></div>
        <div className={css.header}>
          <div>{_("stats", "box_performed")}</div>
          <div>{_("stats", "box_unable")}</div>
          <div>{_("stats", "box_not_performed")}</div>
        </div>

        <div className={css.percents}>
          <div>{percent_performed + "%"}</div>
          <div>{percent_unable + "%"}</div>
          <div>{percent_not_performed + "%"}</div>
        </div>

        <div className={css.bargraph_hz}>
          <div
            className={classNames(css.bar, css.green)}
            style={{ width: percent_performed + "%" }}
          ></div>
          <div
            className={classNames(css.bar, css.yellow)}
            style={{ width: percent_unable + "%" }}
          ></div>
          <div
            className={classNames(css.bar, css.red)}
            style={{ width: percent_not_performed + "%" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default BoxSignOff;
