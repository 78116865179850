import React, { useState, useEffect } from "react";
import { getLanguageString as _ } from "misc/lang";

import { DefaultAndAuthProps } from "model/props";
import {
  apiGetStaffList,
  apiStaffCreate,
  apiStaffDelete,
  apiStaffUpdate,
  apiStaffUpdateSignin,
} from "net/requests";
import SearchableList from "components/listeditor/SearchableList";
import { deleteLocal, matchItem, saveLocal } from "misc/list_helper";

import Editor from "components/listeditor/Editor";

import cssLayout from "../layout.module.scss";
import css from "./staffscreen.module.scss";
import { Staff, StaffFields } from "model/staff";

const StaffScreen: React.FC<DefaultAndAuthProps> = (props) => {
  const { admin, account, home, loader } = props;

  const [staff, setStaff] = useState<Staff[]>([]);
  const [selected, setSelected] = useState<Staff>();
  const [editorItem, updateEditorItem] = useState<Staff>();

  /////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (admin?.access_token && account) {
      setStaff([]);
      actionLoadStaff();
    }
    // eslint-disable-next-line
  }, [home]);

  useEffect(() => {
    if (!selected && staff && staff.length) setSelected(staff[0]);
    // eslint-disable-next-line
  }, [staff]);

  useEffect(() => {
    if (selected) {
      updateEditorItem({ ...selected });
    } else {
      updateEditorItem(undefined);
    }
    // eslint-disable-next-line
  }, [selected]);
  /////////////////////////////////////////////////////////////////////////

  const actionLoadStaff = () => {
    if (!home) return;

    apiGetStaffList(admin, home.uuid)
      .then((response) => {
        setStaff(response.data);
      })
      .catch((error) => {
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  /////////////////////////////////////////////////////////////////////////

  const actionCancel = () => {
    if (selected) updateEditorItem({ ...selected });
  };

  const actionNew = () => {
    if (!home) return;

    loader.setLoading();
    apiStaffCreate(admin, home.uuid, "")
      .then((response) => {
        loader.reset();
        setStaff([...staff, response.data]);
        setSelected(response.data);
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  const actionSave = () => {
    if (!editorItem || !selected) return;

    loader.setLoading();
    apiStaffUpdate(admin, selected.uuid, editorItem)
      .then((response) => {
        loader.reset();
        saveLocal(staff, editorItem, setStaff);
        setSelected(editorItem);
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  const actionDelete = () => {
    if (!selected) return;

    loader.setLoading();
    apiStaffDelete(admin, selected.uuid)
      .then((response) => {
        loader.reset();
        deleteLocal(staff, selected.uuid, setStaff);
        setSelected(undefined);
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  /////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div className={cssLayout.screen}>
        <div className={cssLayout.pageHeading}>
          {(home?.name || "") + " - " + _("staff", "page_heading")}
        </div>
        <div className={css.homes}>
          <div className={cssLayout.listeditor}>
            <div className={cssLayout.list}>
              <SearchableList
                items={staff}
                matchItem={matchItem}
                onSelect={setSelected}
                selected={selected}
                onAddNew={actionNew}
                getLabel={(item: Staff) => item.identifier}
              ></SearchableList>
            </div>
            <div className={cssLayout.editor}>
              {selected && (
                <>
                  {editorItem && (
                    <Editor
                      item={editorItem}
                      updateItem={updateEditorItem}
                      fields={StaffFields}
                      getLabel={(item: Staff) => item.identifier}
                      string_group={"staff"}
                      onDelete={actionDelete}
                      onSave={actionSave}
                      onCancel={actionCancel}
                    />
                  )}
                  {!selected && _("staff", "select_account_from_list")}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffScreen;
