import React, { useState, useRef, useEffect } from "react";

import { DefaultProps } from "model/props";
import { getLanguageString as _ } from "misc/lang";

import css from "./wellbeing.module.scss";
import cssLayout from "screens/layout.module.scss";

import { incrementWellbeingSummary } from "misc/stats_util";
import {
  Wellbeing as WellbeingData,
  WellbeingSummary as WellbeingSummaryData,
  newWellbeingSummary,
} from "model/stats";
import WellbeingCalendar from "./WellbeingCalendar";
import WellbeingSummary from "./WellbeingSummary";
import WellbeingDayList from "./WellbeingDayList";

export interface Props extends DefaultProps {
  year: number;
  month: number;
  data: WellbeingData[][][];
  yearSummary: WellbeingSummaryData;
}

const Wellbeing: React.FC<Props> = (props) => {
  const { year, month, data, yearSummary } = props;

  const [daySelected, setDaySelected] = useState<number>();

  /////////////////////////////////////////////////////////////////////////

  return (
    <>
      {month >= 0 && (
        <div className={cssLayout.subHeading}>{_("wellbeing", "calendar_info")}</div>
      )}
      <div className={css.wellbeing}>
        {/* HEADER */}
        {month >= 0 && (
          <WellbeingCalendar
            {...props}
            daySelected={daySelected}
            setDaySelected={setDaySelected}
          />
        )}
        {!daySelected && <WellbeingSummary {...props} />}
        {daySelected && (
          <WellbeingDayList
            {...props}
            daySelected={daySelected}
            setDaySelected={setDaySelected}
          />
        )}
      </div>
    </>
  );
};

export default Wellbeing;
