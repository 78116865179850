import axios from "axios";

import { Admin } from "model/admin";

// const baseUrlDev = "https://addyou-dev.mihost.se/";
// const baseUrlProd = "https://api.addyou.se/";
// const baseUrlLocal = "http://localhost:8000/";
// //const baseUrlLocal = "https://api.addyou.se/";

// const REACT_APP_BUILD = process.env.REACT_APP_BUILD;
const baseUrl = "https://api.addyou.se/"

///////////////////////////////////////////////////////////////////////////
const getSendHeaders = (admin?: Admin, contentType: string = "application/json") => {
  return getHeaders(admin, contentType);
};

const getHeaders = (admin?: Admin, contentType?: string) => {
  const headers: { [name: string]: string } = {};
  if (admin) headers["Access-Token"] = admin.access_token || "";
  if (contentType) headers["Content-Type"] = contentType;
  return headers;
};

const axiosPost = (path: string, data?: any, admin?: Admin) => {
  return axios.post(`${baseUrl}${path}`, data && JSON.stringify(data), {
    headers: getSendHeaders(admin),
  });
};
const axiosPut = (path: string, data?: any, admin?: Admin) => {
  return axios.put(`${baseUrl}${path}`, data && JSON.stringify(data), {
    headers: getSendHeaders(admin),
  });
};

const axiosDelete = (path: string, admin?: Admin) => {
  return axios.delete(`${baseUrl}${path}`, {
    headers: getHeaders(admin),
  });
};

const axiosGet = (
  path: string,
  admin?: Admin,
  params?: { [name: string]: string | number }
) => {
  return axios.get(`${baseUrl}${path}`, {
    headers: getHeaders(admin),
    params,
  });
};

///////////////////////////////////////////////////////////////////////////

export const apiRegisterEmailPassword = (
  email: string,
  password: string,
  invitationToken?: string
) => {
  const data = {
    email,
    password,
    invitation_token: invitationToken,
    website: window.location.origin,
  };
  return axiosPost("auth/register-email-password", data);
};

export const apiSignInEmailPassword = (email: string, password: string) => {
  const data = {
    email,
    password,
  };
  return axiosPost("auth/signin-email-password", data);
};

export const apiSignInAccessToken = (accessToken: string) => {
  const admin: Admin = { uuid: "", name: "", access_token: accessToken };
  return axiosPost("auth/signin-access-token", null, admin);
};

export const apiTriggerPasswordReset = (email: string) => {
  const data = {
    email: email.trim().toLowerCase(),
    website: window.location.origin,
  };
  return axiosPost("auth/trigger-reset-password", data);
};

export const apiResetPassword = (email: string, token: string, newPassword: string) => {
  const data = {
    email: email.trim().toLowerCase(),
    token,
    new_password: newPassword,
  };
  return axiosPut("auth/reset-password", data);
};

///////////////////////////////////////////////////////////////////////////

export const apiGetAccount = (admin: Admin) => {
  return axiosGet("account", admin);
};
export const apiGetAccountList = (admin: Admin) => {
  return axiosGet("account/list", admin);
};
export const apiAccountCreate = (admin: Admin, data: any) => {
  return axiosPost("account", data, admin);
};
export const apiAccountUpdate = (admin: Admin, uuid: string, data: any) => {
  return axiosPut("account/" + uuid, data, admin);
};
export const apiAccountDelete = (admin: Admin, uuid: string) => {
  return axiosDelete("account/" + uuid, admin);
};
export const apiGetAdminList = (admin: Admin, uuid: string) => {
  return axiosGet("account/" + uuid + "/admin/list", admin);
};

///////////////////////////////////////////////////////////////////////////

export const apiGetHomesList = (admin: Admin, account_uuid?: string) => {
  return axiosGet(
    "home/list" + (account_uuid ? "?account=" + account_uuid : ""),
    admin
  );
};

export const apiHomeCreate = (admin: Admin, data: any) => {
  return axiosPost("home", data, admin);
};

export const apiHomeUpdate = (admin: Admin, uuid: string, data: any) => {
  return axiosPut("home/" + uuid, data, admin);
};
export const apiHomeDelete = (admin: Admin, uuid: string) => {
  return axiosDelete("home/" + uuid, admin);
};

export const apiGetManagerList = (admin: Admin, uuid: string, type: string) => {
  return axiosGet("home/" + uuid + "/manager/" + type, admin);
};

export const apiHomeUpdateSigninQR = (admin: Admin, uuid: string) => {
  return axiosPost("home/" + uuid + "/new_qr", null, admin);
};

///////////////////////////////////////////////////////////////////////////

export const apiGetUserList = (admin: Admin, home_uuid: string) => {
  return axiosGet("home/" + home_uuid + "/user/list", admin);
};

export const apiUserCreate = (admin: Admin, home_uuid: string, name: string) => {
  const data = {
    name,
  };
  return axiosPost("home/" + home_uuid + "/user", data, admin);
};
export const apiUserUpdate = (admin: Admin, uuid: string, data: any) => {
  return axiosPut("home/user/" + uuid, data, admin);
};
export const apiUserDelete = (admin: Admin, uuid: string) => {
  return axiosDelete("home/user/" + uuid, admin);
};
export const apiUserUpdateSignin = (admin: Admin, uuid: string) => {
  return axiosPost("home/user/" + uuid + "/update_signin", null, admin);
};
///////////////////////////////////////////////////////////////////////////

export const apiGetStaffList = (admin: Admin, home_uuid: string) => {
  return axiosGet("home/" + home_uuid + "/staff/list", admin);
};

export const apiStaffCreate = (admin: Admin, home_uuid: string, name: string) => {
  const data = {
    name,
  };
  return axiosPost("home/" + home_uuid + "/staff", data, admin);
};
export const apiStaffUpdate = (admin: Admin, uuid: string, data: any) => {
  return axiosPut("home/staff/" + uuid, data, admin);
};
export const apiStaffDelete = (admin: Admin, uuid: string) => {
  return axiosDelete("home/staff/" + uuid, admin);
};
export const apiStaffUpdateSignin = (admin: Admin, uuid: string) => {
  return axiosPost("home/staff/" + uuid + "/update_signin", null, admin);
};

///////////////////////////////////////////////////////////////////////////

export const apiAssignAccountAdmin = (
  admin: Admin,
  account_uuid: string,
  email_address: string
) => {
  const data = { email: email_address, website: window.location.origin };

  return axiosPut("account/" + account_uuid + "/admin", data, admin);
};
export const apiRemoveAccountAdmin = (
  admin: Admin,
  account_uuid: string,
  admin_uuid: string
) => {
  return axiosDelete("account/" + account_uuid + "/admin/" + admin_uuid, admin);
};

export const apiAssignManager = (
  admin: Admin,
  home_uuid: string,
  type: string,
  email_address: string
) => {
  const data = { email: email_address, website: window.location.origin };

  return axiosPut("home/" + home_uuid + "/manager/" + type, data, admin);
};

export const apiRemoveManager = (
  admin: Admin,
  home_uuid: string,
  type: string,
  admin_uuid: string
) => {
  return axiosDelete(
    "home/" + home_uuid + "/manager/" + type + "/" + admin_uuid,
    admin
  );
};

///////////////////////////////////////////////////////////////////////////

export const apiGetAccountStats = (
  admin: Admin,
  account_uuid: string,
  year: number
) => {
  return axiosGet("stats/account/" + account_uuid + "?year=" + year, admin);
};

export const apiGetHomeStats = (admin: Admin, home_uuid: string, year: number) => {
  return axiosGet("stats/home/" + home_uuid + "?year=" + year, admin);
};

export const apiGetUserStats = (admin: Admin, user_uuid: string, year: number) => {
  return axiosGet("stats/user/" + user_uuid + "?year=" + year, admin);
};

export const apiGetStaffStatsHome = (admin: Admin, home_uuid: string, year: number) => {
  return axiosGet("stats/home/" + home_uuid + "/staff?year=" + year, admin);
};

export const apiGetStaffStatsUser = (
  admin: Admin,
  home_uuid: string,
  user_uuid: string,
  year: number
) => {
  return axiosGet("stats/user/" + user_uuid + "/staff" + "?year=" + year, admin);
};

///////////////////////////////////////////////////////////////////////////

export const apiGetUserCalendarHistory = (
  admin: Admin,
  user_uuid: string,
  year: number
) => {
  return axiosGet(
    "stats/user/" + user_uuid + "/calendar/history" + "?year=" + year,
    admin
  );
};

///////////////////////////////////////////////////////////////////////////

export const apiGetCalendarHomeAgenda = (
  admin: Admin,
  home_uuid: string,
  day_offset: number,
  days: number
) => {
  return axiosGet(
    "home/" + home_uuid + "/agenda?offset=" + day_offset + "&days=" + days,
    admin
  );
};

///////////////////////////////////////////////////////////////////////////

export const apiGetWellbeingHistory = (
  admin: Admin,
  user_uuid: string,
  year: number
) => {
  return axiosGet("user/wellbeing/" + user_uuid + "/list?year=" + year, admin);
};
