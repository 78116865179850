export const access = {
  access_head: "Manage Access",
  access_desc:
    "Manage account access and send invitations.\nSupported in Team plan only.",

  invite_head: "Invite User",
  invite_desc: "Invite as additional user to your account",

  label_email: "Email address",

  button_invite: "Send Invitation",

  msg_invite_success_head: "Invitation Sent",
  msg_invite_success: "",

  msg_invite_fail_head: "Invitation Not Sent",
  msg_invite_fail_user_connected_to_other_account:
    "User already connected to other account.",

  invitations_head: "Sent Invitations",
  invitations_desc: "Manage your sent invitations",
  invitations_none: "No invitations",
  users_head: "Account Users",
  users_desc: "Manage account users",

  button_copylink: "Copy Link",
  button_copylink_tooltip: "Copy invitation link",

  button_delete_invitation: "Delete",
  button_delete_invitation_tooltip: "Delete invitation",

  button_delete_user: "Remove",
  button_delete_user_tooltip: "Remove user from account",
};
