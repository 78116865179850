import React, { useState, useEffect } from "react";
import { getLanguageString as _ } from "misc/lang";

import { DefaultAndAuthProps } from "model/props";
import { Account, AccountFields } from "model/account";
import {
  apiAccountCreate,
  apiAccountDelete,
  apiAccountUpdate,
  apiAssignAccountAdmin,
  apiGetAccountList,
  apiGetAdminList,
  apiRemoveAccountAdmin,
} from "net/requests";
import SearchableList from "components/listeditor/SearchableList";
import { deleteLocal, matchItem, saveLocal } from "misc/list_helper";

import Editor from "components/listeditor/Editor";
import { Admin } from "model/admin";
import AdminList from "components/adminlist/AdminList";

import cssLayout from "../layout.module.scss";
import css from "./accountscreen.module.scss";

const AccountScreen: React.FC<DefaultAndAuthProps> = (props) => {
  const { admin, account, loader, setAccount } = props;

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selected, setSelected] = useState<Account | undefined>(account);
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [editorItem, updateEditorItem] = useState<Account>();
  const [error, setError] = useState<string>();

  /////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!selected && account && !admin.is_super_admin) setSelected(account);

    // eslint-disable-next-line
  }, [account]);

  useEffect(() => {
    if (!selected && accounts && accounts.length) setSelected(accounts[0]);

    // eslint-disable-next-line
  }, [accounts]);

  useEffect(() => {
    if (admin.is_super_admin && admin.access_token) actionLoadAccounts();

    // eslint-disable-next-line
  }, [admin]);

  useEffect(() => {
    setAdmins([]);

    if (selected) {
      if (admin.is_super_admin) setAccount(selected);
      updateEditorItem({ ...selected });
      actionLoadAccountAdmins();
    } else {
      updateEditorItem(undefined);
    }

    // eslint-disable-next-line
  }, [selected]);
  /////////////////////////////////////////////////////////////////////////

  const actionLoadAccounts = () => {
    apiGetAccountList(admin)
      .then((response) => {
        const accounts: Account[] = response.data;
        setAccounts(accounts);
      })
      .catch((error) => {
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  const actionLoadAccountAdmins = () => {
    if (!selected) return;

    apiGetAdminList(admin, selected.uuid)
      .then((response) => {
        setAdmins(response.data);
      })
      .catch((error) => {
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  /////////////////////////////////////////////////////////////////////////

  const actionCancel = () => {
    if (selected) updateEditorItem({ ...selected });
  };

  const actionNew = () => {
    loader.setLoading();
    apiAccountCreate(admin, {})
      .then((response) => {
        loader.reset();
        setAccounts([...accounts, response.data]);
        setSelected(response.data);
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  const actionSave = () => {
    if (!editorItem || !selected) return;

    loader.setLoading();
    apiAccountUpdate(admin, selected.uuid, editorItem)
      .then((response) => {
        loader.reset();
        saveLocal(accounts, editorItem, setAccounts);
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  const actionDelete = () => {
    if (!selected) return;

    loader.setLoading();
    apiAccountDelete(admin, selected.uuid)
      .then((response) => {
        loader.reset();
        deleteLocal(accounts, selected.uuid, setAccounts);
        setSelected(undefined);
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  /////////////////////////////////////////////////////////////////////////

  const actionAssignAdmin = (email: string) => {
    if (!selected) return;

    loader.setLoading();
    apiAssignAccountAdmin(admin, selected.uuid, email)
      .then((response) => {
        loader.reset();
        const new_admin: Admin = response.data;
        saveLocal(admins, new_admin, setAdmins);
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
        if (errorCode === "conflict") {
          setError(_("account", "error_conflict"));
        }
      });
  };

  const actionRemoveAdmin = (adminToRemove: Admin) => {
    if (!selected) return;
    if (
      !window.confirm(
        _("account", "confirm_remove_admin") + adminToRemove.email_address
      )
    )
      return;

    loader.setLoading();
    apiRemoveAccountAdmin(admin, selected.uuid, adminToRemove.uuid)
      .then((response) => {
        loader.reset();
        deleteLocal(admins, adminToRemove.uuid, setAdmins);
      })
      .catch((error) => {
        loader.reset();
        const errorCode = error.response?.data?.error;
        console.log(errorCode);
      });
  };

  /////////////////////////////////////////////////////////////////////////

  return (
    <div className={cssLayout.screen}>
      <div className={cssLayout.pageHeading}>{_("account", "page_heading")}</div>
      <div className={css.accounts}>
        <div className={cssLayout.listeditor}>
          {admin.is_super_admin && (
            <div className={cssLayout.list}>
              <SearchableList
                items={accounts}
                matchItem={matchItem}
                onSelect={setSelected}
                selected={selected}
                onAddNew={actionNew}
              ></SearchableList>
            </div>
          )}
          <div className={cssLayout.editor}>
            <>
              {editorItem && (
                <Editor
                  item={editorItem}
                  updateItem={updateEditorItem}
                  fields={AccountFields}
                  string_group={"account"}
                  onDelete={(admin.is_super_admin && actionDelete) || undefined}
                  onSave={actionSave}
                  onCancel={actionCancel}
                />
              )}
              {!selected && _("account", "select_account_from_list")}
            </>
            {admins && (
              <>
                <AdminList
                  heading={_("account", "account_admin_heading")}
                  admins={admins}
                  onDelete={actionRemoveAdmin}
                  onAssign={actionAssignAdmin}
                  string_group={"account"}
                  id={"account_admin"}
                />
                <div className={css.error}>{error}</div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountScreen;
