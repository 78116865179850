import { createTheme } from "@mui/material/styles";

const colorsDark = {
  primary: "#a5bd35",
  secondary: "#FF6E00",
  background: "#444",
  danger: "#e22f1d",
  grey: "#707070",
  type: "dark",
};

const colorsLight = {
  primary: "#3d939f",
  secondary: "#FF6E00",
  background: "#f3f3f3",
  danger: "#e22f1d",
  grey: "#707070",
  type: "light",
};

const getColors = (useDarkMode) => {
  return useDarkMode ? colorsDark : colorsLight;
};

const getTheme = (useDarkMode = false) => {
  const colors = getColors(useDarkMode);

  const theme = createTheme({
    shadows: [
      "none",
      "0px 0px 5px 5px rgba(0, 0, 0, 0.5)",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "0px 0px 5px 5px rgba(0, 0, 0, 0.5)",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
    ],
    palette: {
      mode: useDarkMode ? "dark" : "light",
      primary: {
        main: colors.primary,
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: colors.secondary,
        contrastText: "#FFFFFF",
      },
      background: colors.background,
    },
    typography: {
      useNextVariants: true,
      fontFamily: "Inter, Roboto, sans-serif",
      fontSize: 13,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 600,
    },
  });

  return theme;
};

export { getColors, getTheme };
