import React, { useState, useEffect } from "react";
import { getLanguageString as _ } from "misc/lang";

import css from "../../components/form/form.module.scss";
import TextInput from "../../components/form/TextInput";
import { validateEmailAddress } from "misc/validators";
import { DefaultAndAuthProps } from "model/props";
import FormButton from "../../components/form/FormButton";
import { signInWithEmailPassword, triggerPasswordReset } from "misc/auth_utils";

const LoginScreen: React.FC<DefaultAndAuthProps> = (props) => {
  const { loader } = props;

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");

  const [modeForgotPassword, setModeForgotPassword] = useState<boolean>(false);

  const canLogin = !!email && !!password;

  useEffect(() => {
    setError("");
  }, [modeForgotPassword]);

  /////////////////////////////////////////////////////////////////////////

  const actionLogin = (event: any) => {
    event.preventDefault();
    if (!canLogin) return;

    signInWithEmailPassword(email, password, loader, props, setError);
  };

  const actionTriggerPasswordReset = (event: any) => {
    event.preventDefault();
    if (!email) return;

    triggerPasswordReset(email, loader, setError);
  };
  /////////////////////////////////////////////////////////////////////////

  return (
    <div className={css.form}>
      <form onSubmit={actionLogin}>
        {modeForgotPassword && (
          <>
            <div className={css.heading}>{_("auth", "forgot_password_head")}</div>
            <div className={css.info}>{_("auth", "forgot_password_info")}</div>
          </>
        )}
        {!modeForgotPassword && (
          <>
            <div className={css.heading}>{_("auth", "login_head")}</div>
            <div className={css.info}>{_("auth", "login_info")}</div>
          </>
        )}

        <TextInput
          autocomplete="email"
          label={_("general", "email")}
          initialValue={email}
          updateValue={setEmail}
          validator={validateEmailAddress}
        />

        {modeForgotPassword && (
          <>
            <FormButton
              label={_("auth", "btn_trigger_password_reset")}
              onSubmit={actionTriggerPasswordReset}
              disabled={!email}
            />
            <FormButton
              label={_("general", "btn_cancel")}
              onSubmit={() => {
                setModeForgotPassword(false);
              }}
              variant={"outlined"}
            />
          </>
        )}

        {!modeForgotPassword && (
          <>
            <TextInput
              autocomplete="current-password"
              type="password"
              label={_("general", "password")}
              initialValue={password}
              updateValue={setPassword}
            />
            <FormButton
              label={_("auth", "btn_login")}
              onSubmit={actionLogin}
              disabled={!canLogin}
            />
            <FormButton
              label={_("auth", "btn_forgot_password")}
              onSubmit={() => {
                setModeForgotPassword(true);
              }}
              variant={"text"}
            />
          </>
        )}
      </form>
      {error && <div className={css.error}>{error}</div>}
    </div>
  );
};

export default LoginScreen;
