import React from "react";
import CloseIcon from "@mui/icons-material/Close";

import { getLanguageString as _ } from "misc/lang";
import { getLanguageStringArray as _ar } from "misc/lang";

import css from "./wellbeing.module.scss";

import { Wellbeing as WellbeingData } from "model/stats";
import { WELLBEING_HAPPY, WELLBEING_NEUTRAL, WELLBEING_SAD } from "appConfig";
import {
  formatTimestampDate,
  getJSDate,
  getTimeString,
  unixtime2JSDate,
} from "misc/date_utils";
import { Props as WellbeingProps } from "./WellbeingCalendar";
import { IconButton } from "@mui/material";

const WellbeingDayList: React.FC<WellbeingProps> = (props) => {
  const { year, month, data, daySelected, setDaySelected } = props;

  if (daySelected === undefined) return <></>;

  const date = new Date(year, month, daySelected);
  const dayData = data[month][daySelected - 1] || [];

  /////////////////////////////////////////////////////////////////////////
  const getListItem = (wb: WellbeingData) => {
    const happy = wb.wellbeing === WELLBEING_HAPPY;
    const neutral = !happy && wb.wellbeing === WELLBEING_NEUTRAL;
    const sad = !happy && !neutral && wb.wellbeing === WELLBEING_SAD;

    const cssClass =
      (happy && css.happy) || (neutral && css.neutral) || (sad && css.sad) || undefined;

    const date = unixtime2JSDate(wb.unix_timestamp);

    return (
      <div key={"wb" + wb.unix_timestamp + "_" + wb.wellbeing} className={cssClass}>
        <div className={css.time}>{getTimeString(date)}</div>
        <div className={css.dot}></div>
        <div className={css.title}>{_("wellbeing", wb.wellbeing)}</div>
      </div>
    );
  };

  const getList = () => {
    const list = [];
    for (const wb of dayData) {
      const item = getListItem(wb);
      list.push(item);
    }
    if (!list.length) return _("wellbeing", "no_entries");
    return list;
  };

  return (
    <div className={css.wellbeing_daylist}>
      <div className={css.head}>
        <div className={css.title}>{formatTimestampDate(date, true)}</div>
        <IconButton
          className={css.close}
          onClick={() => setDaySelected && setDaySelected(undefined)}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={css.list}>
        <div className={css.content}>{getList()}</div>
      </div>
    </div>
  );
};

export default WellbeingDayList;
