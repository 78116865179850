import React, { useState } from "react";
import { getLanguageString as _ } from "misc/lang";

import TextInput from "components/form/TextInput";
import AdminItem from "./AdminItem";

import FormButton from "components/form/FormButton";
import { Admin } from "model/admin";

import css from "./adminlist.module.scss";
import { validateEmailAddress } from "misc/validators";
export interface Props {
  heading?: string;
  admins: Admin[];
  onDelete: (admin: Admin) => void;
  onAssign: (email: string) => void;
  string_group: string;
  id: string;
}

const AdminList: React.FC<Props> = (props) => {
  const { heading, admins, onDelete, onAssign, string_group, id } = props;
  const [assignEmail, setAssignEmail] = useState<string>("");

  /////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////

  return (
    <div className={css.adminlist}>
      {heading && <div className={css.heading}>{heading}</div>}
      <div className={css.adminCount}>
        {admins?.length || 0} {_("account", "number_admins_assigned")}
      </div>
      <div className={css.admins}>
        {admins &&
          admins.map((admin) => {
            return (
              <AdminItem
                key={admin.uuid}
                admin={admin}
                onDelete={() => onDelete(admin)}
              />
            );
          })}
      </div>
      <div className={css.assign}>
        <TextInput
          label={_(string_group, id + "_add_placeholder")}
          initialValue={assignEmail}
          updateValue={setAssignEmail}
          validator={validateEmailAddress}
        />
        <div className={css.assignButton}>
          <FormButton
            label={_("general", "btn_assign")}
            onSubmit={(event) => onAssign(assignEmail)}
            inline={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminList;
